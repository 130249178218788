import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { Pagination } from '~shared/data/models/pagination.model';
import { TableColumn } from '~shared/data/models/table-column.model';
import { SortableColumnDirective, SortEvent } from '~shared/directives/sortable-column.directive';

@Component({
  selector: 'cm-paginated-table',
  templateUrl: './paginated-table.component.html',
  styleUrls: ['./paginated-table.component.scss']
})
export class PaginatedTableComponent implements OnInit {
  @ViewChildren(SortableColumnDirective) headers: QueryList<SortableColumnDirective>;
  @Input() tableRows: any[];
  @Input() tableColumns: TableColumn[];
  @Input() pagination: Pagination;

  @Output() sortChanged: EventEmitter<SortEvent> = new EventEmitter<SortEvent>();
  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() rowClicked: EventEmitter<number> = new EventEmitter<number>();
  @Output() rowDoubleClicked: EventEmitter<number> = new EventEmitter<number>();

  selectedRow: number;

  constructor() {}

  ngOnInit() {}

  onRowClicked(index: number) {
    this.selectedRow = index;
    this.rowClicked.emit(index);
  }

  onRowDoubleClicked(index: number) {
    this.rowDoubleClicked.emit(index);
  }

  /**
   * Event that is fired when a table column is clicked
   * @param sortEvent sort the column
   *
   */
  onSort(event: SortEvent) {
    const { column, direction } = event;
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.tableRows = this.sort(this.tableRows, column, direction);
    this.sortChanged.emit(event);
  }

  /**
   * Event that is fired when a pagination handler is clicked
   *
   * @param {number} page
   */
  onPageChanged(page: number) {
    this.pageChanged.emit(page);
  }

  /**
   * Sort the table data
   * @param data Table field value
   * @param column Fetch the column
   * @param direction Sort direction Ascending or Descending
   */
  protected sort(data: any[], column: string, direction: string): any[] {
    const compare = (v1, v2) => {
      return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
    };
    if (direction === '') {
      return data;
    } else {
      return [...data].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  /**
   * Table Data Match with Search input
   * @param item Table field value fetch
   * @param term Search the value
   */
  protected matches(item: any, columns: string[], term: string): boolean {
    return (
      columns.findIndex(column =>
        item[column] ? item[column].toLowerCase().includes(term) : false
      ) > -1
    );
  }
}
