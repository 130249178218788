export interface PaginationResult<T> {
  items: T[];
  itemCount: number;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export function emptyPaginationResult() {
  return {
    items: [],
    itemCount: 0,
    currentPage: 1,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0,
    hasNextPage: false,
    hasPreviousPage: false
  };
}
