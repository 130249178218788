import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthorizationService } from '~auth/data/services/authorization.service';
import { UserRole } from '../data/models/authorization.model';

@Directive({
  selector: '[authShow]'
})
export class AuthShowDirective implements OnInit {
  @Input('authShow') roles: UserRole[];

  constructor(private el: ElementRef, private authorizationService: AuthorizationService) {}

  ngOnInit() {
    const hasRequiredRole =
      this.roles?.some(role => this.authorizationService.hasRole(role)) || false;
    if (!hasRequiredRole) this.el.nativeElement.setAttribute('style', 'display: none !important;');
  }
}
