export class PatientLogItem {
  createdOn: string;
  practitionerName: string;
  text: string;
  category: string;

  constructor(json: any) {
    this.createdOn = json.createdOn;
    this.practitionerName = json.practitionerName;
    this.text = json.text;
    this.category = json.category;
  }
}
