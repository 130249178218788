/**
 * Usage: number | localNumber:'format'
 * If no format is provided 2 decimals will be used.
 **/

import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localNumber'
})
export class LocalNumberPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: any, format?: string) {
    if (value == null) {
      return '';
    }
    if (!format) {
      format = '.0-2';
    }

    return formatNumber(value, this.translate.currentLang, format);
  }
}
