export function getHighestAlertClass<T>(
  collection: T[] = [],
  alertClassGetFn: (item: T) => string
) {
  const severityClassesInAscendingOrder = ['info', 'warning', 'danger'];
  const severityIndex = collection.reduce(
    (max, current) =>
      Math.max(max, severityClassesInAscendingOrder.indexOf(alertClassGetFn(current))),
    -1
  );

  return severityIndex >= 0 ? severityClassesInAscendingOrder[severityIndex] : 'primary';
}
