import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import * as OT from '@opentok/client';
import { environment } from 'src/environments/environment';
import { AlertService } from '~shared/services/alert.service';
import { LoggingService } from '~shared/services/logging.service';
import { Subscription } from 'rxjs';

export interface VideoChatClientError {
  message: string;
  name: string;
}

@Component({
  selector: 'cm-vonage-video-client',
  templateUrl: './vonage-video-client.component.html',
  styleUrls: ['./vonage-video-client.component.scss']
})
export class VonageVideoClientComponent implements OnInit, OnDestroy {
  private readonly apiKey = environment.tokbox.apiKey;

  @Output() callEnded = new EventEmitter();
  @Input() sessionId: string;
  @Input() token: string;
  @Input() isHost = false;

  /**
   * Indicates that the session connection has been established
   */
  session: OT.Session;
  streams: Array<OT.Stream> = [];
  isConnected = false;
  isPublished = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private alertService: AlertService,
    private logger: LoggingService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  get hasRemoteConnection() {
    return this.streams.length > 0;
  }

  ngOnInit(): void {
    this.getDevices().then(devices => console.log(JSON.stringify(devices, null, 2)));
    this.initializeSession();
  }

  join() {
    if (this.isConnected && !this.isPublished) {
      // this.publish();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.logger.debug(`Disconnecting from session`);
    if (this.session) {
      this.session.disconnect();
      this.session = null;
    }
  }

  private initializeSession() {
    this.session = OT.initSession(this.apiKey, this.sessionId);

    // Subscribe to a newly created stream
    this.session.on('streamCreated', event => {
      this.logger.debug(
        `Stream created (id: ${event.stream.streamId}), name: ${event.stream.name})`
      );
      this.streams.push(event.stream);
      this.changeDetectorRef.detectChanges();
    });

    this.session.on('streamDestroyed', event => {
      this.logger.debug(
        `Stream destroyed (id: ${event.stream.streamId}), name: ${event.stream.name})`
      );
      const idx = this.streams.indexOf(event.stream);
      if (idx > -1) {
        this.streams.splice(idx, 1);
        this.changeDetectorRef.detectChanges();
      }
      if (this.streams.length === 0) {
        // if there are no remote streams left, leave the call
        this.callEnded.emit();
      }
    });

    // sessionDisconnected event indicates that the local client has disconnected from the session
    // this.session.on('sessionDisconnected', event => {
    //   this.callEnded.emit();
    // });

    // connectionDestroyed event indicates that a remote stream has disconnected from the session
    // this.session.on('connectionDestroyed', event => {
    //   this.hasRemoteConnection = false;
    //   this.leave();
    //
    //   console.log(`Remote stream ended (reason: ${event.reason})`);
    // });

    // Connect to the session
    this.session.connect(this.token, error => {
      if (error) {
        this.isConnected = false;
        this.callEnded.emit({ message: error.message, name: error.name });
      } else {
        this.isConnected = true;
        this.logger.info(`Session connected`);
      }
    });
  }

  private getDevices() {
    return new Promise((resolve, reject) => {
      OT.getDevices((error, devices) => {
        resolve(devices);
      });
    });
  }

  endCall() {
    this.callEnded.emit();
  }
}
