import { Injectable } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export type AlertType = 'success' | 'danger' | 'warning' | 'info';

export interface Alert {
  type: AlertType;
  message: string;
  title?: string;
  id?: number;
}

export interface AlertMessage {
  key?: string;
  keyParams?: { [key: string]: any };
}

@Injectable()
export class AlertService {
  private alertCounter = 0;
  private alerts = new BehaviorSubject<Array<Alert>>([]);
  public alerts$ = this.alerts.asObservable();

  constructor(private translateService: TranslateService) {}

  showSuccess(message: string | AlertMessage, title?: string) {
    this.showAlert({
      type: 'success',
      message: this.getTranslatedAlertMessage(message),
      title
    });
  }

  showError(message: string | AlertMessage, title?: string) {
    this.showAlert({
      type: 'danger',
      message: this.getTranslatedAlertMessage(message),
      title
    });
  }

  showWarning(message: string | AlertMessage, title?: string) {
    this.showAlert({
      type: 'warning',
      message: this.getTranslatedAlertMessage(message),
      title
    });
  }

  showInfo(message: string | AlertMessage, title?: string) {
    this.showAlert({
      type: 'info',
      message: this.getTranslatedAlertMessage(message),
      title
    });
  }

  showAlert(alert: Alert, duration?: number) {
    this.alertCounter++;
    alert.id = this.alertCounter;
    if (!duration) duration = alert.type === 'danger' ? 30000 : 5000;
    this.alerts.next(this.alerts.getValue().concat([alert]));
    this.alerts.pipe(debounceTime(duration)).subscribe(res => {
      this.closeAlert(alert);
    });
  }

  closeAlert(alert: Alert) {
    const alertsValue: any = this.alerts.getValue();
    alertsValue.forEach((item, index) => {
      if (item.id === alert.id) {
        alertsValue.splice(index, 1);
        this.alertCounter--;
        this.alerts.next(alertsValue);
      }
    });
  }

  private getTranslatedAlertMessage(message: string | AlertMessage) {
    message = message || 'Unknown error';
    return typeof message === 'string'
      ? this.translateService.instant(message)
      : this.translateService.instant(message.key, message.keyParams);
  }
}
