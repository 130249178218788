import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FaConfig, FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faBell,
  faCalendarWeek,
  faClipboardList,
  faCommentMedical,
  faDiagnoses,
  faFileMedical,
  faFileMedicalAlt,
  faHeadset,
  faIdCard,
  faIdCardAlt,
  faNotesMedical,
  faPills,
  faPlus,
  faSpinner,
  faStethoscope,
  faTimes
} from '@fortawesome/free-solid-svg-icons';

@NgModule({
  declarations: [],
  imports: [CommonModule, FontAwesomeModule]
})
export class IconsModule {
  constructor(private library: FaIconLibrary, private faConfig: FaConfig) {
    faConfig.fixedWidth = true;

    library.addIcons(
      faBell,
      faCalendarWeek,
      faClipboardList,
      faCommentMedical,
      faDiagnoses,
      faFileMedical,
      faFileMedicalAlt,
      faHeadset,
      faIdCard,
      faIdCardAlt,
      faNotesMedical,
      faPills,
      faPlus,
      faSpinner,
      faStethoscope,
      faTimes
    );
  }
}
