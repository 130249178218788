import { Measurement } from './measurement.model';
import { ThresholdType } from '../../../user-profile/data/models/thresholdType.model';

export class TelemetryDataSet {
  deviceType: string;
  hasUpdatedVitals: boolean;
  measurements: Measurement[];
  thresholdProperties: ThresholdType[];

  constructor(json: any) {
    this.deviceType = json.deviceType;
    this.hasUpdatedVitals = json.hasUpdatedVitals;
    if (json.measurements && Array.isArray(json.measurements)) {
      this.measurements = json.measurements.map(x => new Measurement(x));
    } else if (json.measurements) {
      this.measurements = [new Measurement(json.measurements)];
    }
    this.thresholdProperties = json.thresholdProperties;
  }

  getMeasurementValue(thresholdProp: string, index = 0) {
    return this.measurements[index][thresholdProp];
  }

  getThresholdStatus(thresholdProp: string, index = 0) {
    return this.measurements[index][`${thresholdProp}ThresholdStatus`];
  }

  getAlertValue(thresholdProp: string) {
    switch (this.getThresholdStatus(thresholdProp)) {
      case 'lowExceeded':
      case 'highExceeded':
        return 1;
      case 'highestExceeded':
      case 'lowestExceeded':
        return 2;
      case 'missed':
        return 1;
      case 'normal':
        return 0;
      default:
        return null;
    }
  }

  getAlertClass(thresholdProp: string) {
    switch (this.getThresholdStatus(thresholdProp)) {
      case 'lowExceeded':
      case 'highExceeded':
        return 'warning';
      case 'highestExceeded':
      case 'lowestExceeded':
        return 'danger';
      case 'missed':
        return 'info';
      case 'normal':
        return 'success';
      default:
        return 'dark';
    }
  }
}
