export class DeviceLog {
  id: string;
  chipmunkBarcodeNumber: string;
  patientId: string;
  time: string;
  type: string;
  description: string;

  constructor(json: any) {
    this.id = json.id;
    this.chipmunkBarcodeNumber = json.chipmunkBarcodeNumber;
    this.patientId = json.patientId;
    this.time = json.time;
    this.type = json.type;
    this.description = json.description;
  }
}
