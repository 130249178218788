import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { AlertService } from '~shared/services/alert.service';

@Component({
  selector: 'cm-vonage-video-subscriber',
  templateUrl: './vonage-video-subscriber.component.html',
  styleUrls: ['./vonage-video-subscriber.component.scss']
})
export class VonageVideoSubscriberComponent implements AfterViewInit {
  @ViewChild('subscriberDiv') subscriberDiv: ElementRef;
  @Input() session: OT.Session;
  @Input() stream: OT.Stream;

  subscribed = false;

  constructor(private alertService: AlertService) {}

  ngAfterViewInit(): void {
    this.session.subscribe(
      this.stream,
      this.subscriberDiv.nativeElement,
      {
        insertMode: 'append',
        width: '100%',
        height: '100%'
      },
      error => {
        if (error) {
          this.alertService.showError(error.message);
        } else {
          this.subscribed = true;
          console.log(`Subscribed to stream`);
        }
      }
    );
  }
}
