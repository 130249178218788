import { Component, Directive, TemplateRef } from '@angular/core';
import { ConfirmOptions, ConfirmState } from '~shared/services/confirm-modal.service';

@Component({
  selector: 'cm-confirm-modal',
  templateUrl: './cm-confirm-modal.component.html',
  styleUrls: ['./cm-confirm-modal.component.scss']
})
export class CmConfirmModalComponent {
  options: ConfirmOptions;

  constructor(private state: ConfirmState) {
    this.options = state.options;
  }

  confirm() {
    this.state.modal.close('confirmed');
  }

  cancel() {
    this.state.modal.dismiss('not confirmed');
  }
}

/**
 * Directive allowing to get a reference to the template containing the confirmation modal component,
 * and to store it into the internal confirm state service. Somewhere in the view, there must be
 *
 * ```
 * <template confirm>
 *   <confirm-modal-component></confirm-modal-component>
 * </template>
 * ```
 *
 * in order to register the confirm template to the internal confirm state
 */
@Directive({
  selector: '[confirm]'
})
export class ConfirmTemplateDirective {
  constructor(confirmTemplate: TemplateRef<any>, state: ConfirmState) {
    state.template = confirmTemplate;
  }
}
