import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import {
  ApplicationInsights,
  IEventTelemetry,
  IExceptionTelemetry
} from '@microsoft/applicationinsights-web';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppConfig } from '~shared/app-config';

@Injectable({ providedIn: 'root' })
export class ApplicationInsightsService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  private appInsights: ApplicationInsights;

  constructor(private router: Router) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: AppConfig.settings.azure.insightsInstrumentationKey
      }
    });
    this.appInsights.loadAppInsights();
    this.router.events
      .pipe(filter(event => event instanceof ResolveEnd))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.logPageView(
            `${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`,
            event.urlAfterRedirects
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  logPageView(name?: string, uri?: string) {
    if (window.location.host.indexOf('localhost') > -1) return;
    this.appInsights.trackPageView({ name, uri });
  }

  logException(exception: IExceptionTelemetry) {
    if (window.location.host.indexOf('localhost') > -1) return;
    this.appInsights.trackException(exception);
  }

  logEvent(event: IEventTelemetry) {
    if (window.location.host.indexOf('localhost') > -1) return;
    this.appInsights.trackEvent(event);
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
