/**
 * Usage: dateString | localDate:'format'
 **/

import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { LOCAL_DATE_FORMAT } from '~shared/data/constants/date.constants';

@Pipe({
  name: 'localDate'
})
export class LocalDatePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: any, format: string = LOCAL_DATE_FORMAT) {
    if (!value) {
      return '';
    }
    const momentDate = moment(value);
    return momentDate.isValid()
      ? momentDate.format(format)
      : formatDate(value, format, this.translate.currentLang);
  }
}
