import { IMenuItem } from './menu-item.model';

export default <IMenuItem[]>[
  {
    title: 'MENU.TITLE_DASHBOARD',
    icon: 'mdi mdi-home-outline',
    link: '/dashboard',
    roles: ['DeviceManager', 'OrganizationManager', 'UserAccountManager', 'Practitioner']
  },
  {
    title: 'MENU.TITLE_USERS',
    icon: 'mdi mdi-account-multiple-outline',
    link: '/users',
    roles: ['UserAccountManager'],
    submenu: [
      {
        title: 'MENU.TITLE_OVERVIEW',
        link: ''
      }
    ]
  },
  {
    title: 'MENU.TITLE_ORGANISATIONS',
    icon: 'mdi mdi-home-group',
    link: '/organizations',
    roles: ['OrganizationManager'],
    submenu: [
      {
        title: 'MENU.TITLE_OVERVIEW',
        link: ''
      }
    ]
  },
  {
    title: 'MENU.TITLE_DEVICES',
    icon: 'mdi mdi-scale-bathroom',
    link: '/devices',
    roles: ['DeviceManager'],
    submenu: [
      {
        title: 'MENU.TITLE_OVERVIEW',
        link: ''
      }
    ]
  },
  {
    title: 'MENU.TITLE_AUDITLOG',
    icon: 'mdi mdi-file-document-outline',
    link: '/audit',
    roles: ['AuditEventViewer']
  }
];
