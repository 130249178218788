<div class="modal-header bg-secondary">
  <h4 class="modal-title text-white" id="modal-basic-title">
    {{ title }}
  </h4>
  <button type="button" class="close text-white" aria-label="Close" (click)="onDismissClicked()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- Modal content goes here -->
  <ng-content></ng-content>
</div>
<div class="modal-footer" [ngClass]="showFooter ? '' : 'hidden'">
  <div class="form-group d-flex flex-row-reverse">
    <button
      type="submit"
      class="btn btn-primary ml-2 btn-ok"
      (click)="onSubmitClicked()"
      [disabled]="submitDisabled"
    >
      {{ 'COMMON.OK' | translate }}
    </button>
    <button type="button" class="btn btn-outline-primary" (click)="onCancelClicked()">
      {{ 'COMMON.CANCEL' | translate }}
    </button>
  </div>
</div>
