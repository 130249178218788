import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '~shared/app-config';
import { ENDPOINT_USERAGREEMENT } from '~shared/data/constants/api.constants';
import { Observable } from 'rxjs';
import {
  IUserAgreementPost,
  IUserAgreementGet,
  IPatientAgreementPost
} from './user-agreement.model';
import { User } from '~core/users/data/models/user.model';
import { map, tap } from 'rxjs/operators';
import { LoggingService } from '~shared/services/logging.service';

@Injectable({ providedIn: 'root' })
export class UserAgreementService {
  constructor(private http: HttpClient, private loggingService: LoggingService) {}

  getUserAgreement(accountId?: string): Observable<IUserAgreementGet> {
    const url = `${AppConfig.settings.api.baseUrl}/${ENDPOINT_USERAGREEMENT}${
      accountId ? `/${accountId}` : ''
    }`;
    return this.http.get<IUserAgreementGet>(url).pipe(
      map(response => ({
        userAccount: new User(response.userAccount),
        loanAgreement: response.loanAgreement,
        dataConsent: response.dataConsent,
        userAgreement: response.userAgreement
      }))
    );
  }

  getAcceptedUserAgreements(accountId?: string): Observable<IUserAgreementGet> {
    const url = `${AppConfig.settings.api.baseUrl}/${ENDPOINT_USERAGREEMENT}${
      accountId ? `/${accountId}` : ''
    }/legaldocuments`;
    return this.http.get<IUserAgreementGet>(url).pipe(
      map(response => ({
        userAccount: new User(response.userAccount),
        loanAgreement: response.loanAgreement,
        dataConsent: response.dataConsent,
        userAgreement: response.userAgreement
      }))
    );
  }

  setPatientAgreement(userAgreement: IPatientAgreementPost): Observable<IPatientAgreementPost> {
    return this.http
      .post<IPatientAgreementPost>(
        `${AppConfig.settings.api.baseUrl}/${ENDPOINT_USERAGREEMENT}/patients`,
        {
          ...userAgreement
        }
      )
      .pipe(tap(res => this.loggingService.debug('UserAgreementService', res)));
  }

  setUserAgreement(
    userAgreement: IUserAgreementPost,
    accountId?: string
  ): Observable<IUserAgreementPost> {
    return this.http
      .post<IUserAgreementPost>(
        `${AppConfig.settings.api.baseUrl}/${ENDPOINT_USERAGREEMENT}/users/${accountId}`,
        {
          ...userAgreement
        }
      )
      .pipe(tap(res => this.loggingService.debug('UserAgreementService', res)));
  }
}
