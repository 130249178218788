import { DeviceType } from '~core/devices/data/models/device-type.model';
import {
  PhoneNumberType,
  NotificationMethod,
  UserGender
} from '~core/users/data/constants/user.constants';
import { Patient } from '~core/users/data/models/patient.model';
import { ICountry } from '~shared/data/interfaces/country.interface';
import { DeviceDto } from '~shared/data/models/device-dto.interface';
import {
  countries,
  genders,
  phoneNumberTypes,
  notificationMethods
} from '~shared/data/constants/lists.constants';

export class PatientFormModel {
  patientModel: Patient;
  deviceTypes: DeviceType[];
  deviceIds: string[];
  phoneNumberTypes: PhoneNumberType[];
  countries: ICountry[];
  genders: UserGender[];
  notificationMethods: NotificationMethod[];
  devices: DeviceDto[];

  constructor(patientModel: Patient, deviceTypes: any[], deviceIds?: string[]) {
    this.patientModel = patientModel;
    this.deviceTypes = deviceTypes;
    this.phoneNumberTypes = phoneNumberTypes;
    this.countries = countries;
    this.genders = genders;
    this.notificationMethods = notificationMethods;
    this.devices = patientModel.devices as DeviceDto[];
    this.deviceIds = deviceIds;
  }
}
