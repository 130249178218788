import { MeasurementScheme } from './measurement-scheme.model';

export interface IMeasurementSchemeDto {
  schedule: boolean[][];
  deviceType: string;
}

export class MeasurementSchemeDto implements IMeasurementSchemeDto {
  schedule: boolean[][];
  deviceType: string;

  constructor(json: MeasurementScheme) {
    this.deviceType = json.deviceType;
    this.schedule = json.schedule.map(schedule => schedule.measurementPeriods);
  }
}
