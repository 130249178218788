import { BackendError } from './backend-error.model';

export class BackendValidationError {
  type: string;
  title: string;
  status: number;
  traceId: string;
  errors: BackendError[];

  constructor(json: any) {
    this.type = json.type;
    this.title = json.title;
    this.status = json.status;
    this.traceId = json.traceId;
    this.errors = json.errors;
  }

  toString() {
    const errors = Object.keys(this.errors);
    let message = '';
    errors.forEach(x => {
      if (message.length > 0) message += '\n';
      message += `${x} errors: \n`;
      const subErrors = this.errors[x] || [];
      if (subErrors && subErrors.length > 0) {
        subErrors.forEach(subError => {
          const index = subErrors.indexOf(subError);
          if (index > 0) message += '\n';
          message += `${index + 1}: ${subError}`;
        });
      } else {
        message += 'An unexpected error occurred';
      }
    });
    return message;
  }
}
