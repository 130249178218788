import { NotificationMethod, UserGender } from '~core/users/data/constants/user.constants';
import { AddressModel } from '~shared/data/models/address.model';
import { PatientDiagnosis } from '../../../user-profile/data/models/patient-diagnosis.model';
import { PatientLogItem } from '../../../user-profile/data/models/patient-log.model';
import { PhoneNumber } from './phone-number.model';
import { Threshold } from '~core/user-profile/data/models/thresholds.model';
import { MeasurementScheme } from '~core/user-profile/data/models/measurement-scheme.model';
import { DeviceDto } from '~shared/data/models/device-dto.interface';

export class Patient {
  address: AddressModel;
  shippingAddress: AddressModel;
  phoneNumbers: PhoneNumber[];
  serviceLevel: number;
  serviceStartDate?: Date;
  dateOfBirth: Date;
  gender: UserGender;

  referrerName: string;
  referrerPosition: string;

  // Confirmation of wired internet connection with free ethernet port
  wiredInternetConnectionAvailable: boolean;

  // Consent allowing Chipmunk engagement with patient
  consentForContact: boolean;

  // This is the SIN (Canada), BSN (Netherlands), SSN (America), etc.
  socialIdentityNumber: string;

  // The insurance card number
  healthCardNumber: string;

  accessControllerIntegrationUrl: string;

  accessControllerUrl: string;

  heartRateDeviceId?: string;

  heartRateDevice?: DeviceDto;

  // List of devices assigned to the user
  // TODO: This double type spec is only necessary because this model is used for GET and POST (POST expecting string array)
  devices: DeviceDto[] | string[];

  deviceIds: string[];
  // List of thresholds for the user's devices
  measurementThresholds: Threshold[];

  measurementSchedules: MeasurementScheme[];

  logItems: PatientLogItem[];

  medicalDiagnosis: PatientDiagnosis;

  vitalsLastConfirmed?: Date;

  vitalsLastConfirmedBy?: string;

  emergencyContactName: string;
  emergencyContactEmail: string;
  emergencyContactPhoneNumber: string;

  notificationMethod: NotificationMethod;
  notificationEmail: string;
  notificationPhoneNumber: string;
  notificationPhoneNumberPrefix: string;

  constructor(json: any) {
    this.gender = json.gender;
    this.dateOfBirth = json.dateOfBirth ? new Date(json.dateOfBirth) : null;
    this.serviceLevel = json.serviceLevel;
    this.serviceStartDate = json.serviceStartDate ? new Date(json.serviceStartDate) : null;
    this.address = new AddressModel(json.address || {});
    this.phoneNumbers = json.phoneNumbers?.map(x => new PhoneNumber(x));
    this.logItems = json.logItems?.map(x => new PatientLogItem(x));
    this.healthCardNumber = '123123123123';
    this.socialIdentityNumber = '12332132132132';
    this.referrerName = 'TEST';
    this.referrerPosition = 'Position';
    this.devices = json.devices;
    this.deviceIds = json.deviceIds;
    this.measurementThresholds = json.measurementThresholds?.map(x => new Threshold(x));
    this.measurementSchedules = json.measurementSchedules?.map(x => new MeasurementScheme(x));
    this.medicalDiagnosis = json.medicalDiagnosis;
    this.vitalsLastConfirmed = json.vitalsLastConfirmed ? new Date(json.vitalsLastConfirmed) : null;
    this.vitalsLastConfirmedBy = json.vitalsLastConfirmedBy;
    this.emergencyContactName = json.emergencyContactName;
    this.emergencyContactEmail = json.emergencyContactEmail;
    this.emergencyContactPhoneNumber = json.emergencyContactPhoneNumber;
    this.notificationMethod = json.notificationMethod;
    this.notificationEmail = json.notificationEmail;
    this.notificationPhoneNumber = json.notificationPhoneNumber;
    this.notificationPhoneNumberPrefix = json.notificationPhoneNumberPrefix;
    this.accessControllerIntegrationUrl = json.accessControllerIntegrationUrl;
    this.accessControllerUrl = json.accessControllerUrl;
    this.heartRateDevice = json.heartRateDevice;
    this.heartRateDeviceId = json.heartRateDeviceId;
  }

  public findDevice(deviceType: string) {
    return (<DeviceDto[]>this.devices).find(x => x.type === deviceType);
  }
}
