import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import MetisMenu from 'metismenujs/dist/metismenujs';
import { UserRole } from '~auth/data/models/authorization.model';
import { AuthenticationService } from '~auth/data/services/authentication.service';
import { AuthorizationService } from '~auth/data/services/authorization.service';
import { IMenuItem, MenuItem } from '../data/models/menu-item.model';
import menuItems from '../data/models/menu.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() isCondensed: boolean;
  menu: any;
  menuItems: IMenuItem[] = <MenuItem[]>[];

  @ViewChild('sideMenu') sideMenu: ElementRef;

  constructor(
    private authorizationService: AuthorizationService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    // Rebuild the menu when the userProfile changes.
    this.authenticationService.userProfile$.subscribe(profile => {
      this.menuItems = this.getMenuItems().map(x => new MenuItem(x));
      if (this.menuItems.length <= 1) this.hideSideMenu();
    });
  }

  ngAfterViewInit() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);
    this._activateMenuDropdown();
  }

  ngOnChanges() {
    if ((!this.isCondensed && this.sideMenu) || this.isCondensed) {
      this._activateMenuDropdown();

      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }

  getMenuItems() {
    return menuItems?.filter(item => this.doesUserHaveRoles(<UserRole[]>item.roles));
  }

  isUrlActive(url: string, exact: boolean) {
    return exact ? this.router.url === url : this.router.url.includes(url);
  }

  doesUserHaveRoles(roles: UserRole[]) {
    return roles.some(role => {
      return this.authorizationService.hasRole(role);
    });
  }

  navigateTo(menuItem: MenuItem) {
    if (this.isCondensed) {
      this.router.navigateByUrl(menuItem.link);
    } else if (!this.isCondensed && !menuItem.hasSubmenu()) {
      this.router.navigateByUrl(menuItem.link);
    }
  }

  /**
   * small sidebar
   */
  smallSidebar() {
    document.body.classList.add('left-side-menu-sm');
    document.body.classList.remove('left-side-menu-dark');
    document.body.classList.remove('topbar-light');
  }

  /**
   * Dark sidebar
   */
  darkSidebar() {
    document.body.classList.remove('left-side-menu-sm');
    document.body.classList.toggle('left-side-menu-dark');
  }

  /**
   * Light Topbar
   */
  lightTopbar() {
    document.body.classList.add('left-side-menu-dark');
    document.body.classList.add('topbar-light');
    document.body.classList.remove('left-side-menu-sm');
    document.body.classList.remove('enlarged');
  }

  /**
   * Sidebar collapsed
   */
  sidebarCollapsed() {
    document.body.classList.add('enlarged');
    document.body.classList.remove('left-side-menu-dark');
    document.body.classList.remove('left-side-menu-sm');
    document.body.classList.remove('boxed-layout');
  }

  /**
   * Boxed Layout
   */
  boxedLayout() {
    document.body.classList.toggle('boxed-layout');
    document.body.classList.remove('left-side-menu-dark');
    document.body.classList.add('enlarged');
  }

  hideSideMenu() {
    document.body.className = 'sidebar-disable';
  }

  /**
   * Activates the menu dropdown
   */
  _activateMenuDropdown() {
    const links = document.getElementsByClassName('side-nav-link-ref');
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (window.location.pathname === links[i]['pathname']) {
        menuItemEl = links[i];
        break;
      }
    }

    if (menuItemEl) {
      menuItemEl.classList.add('mm-active');

      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add('mm-active');

        const parent2El = parentEl.parentElement;
        if (parent2El) {
          parent2El.classList.add('in');
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.add('mm-active');
          parent3El.firstChild.classList.add('mm-active');
        }
      }
    }
  }
}
