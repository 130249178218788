/**
 * Usage: dateString | localDate:'format'
 **/

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { LOCAL_DATE_FORMAT, LOCAL_TIME_FORMAT } from '~shared/data/constants/date.constants';

@Pipe({
  name: 'localDateTime'
})
export class LocalDateTimePipe implements PipeTransform {
  transform(value: any, format: string = 'dt', timeZone?: string) {
    if (!value) return '';

    let dt = moment(value);
    if (timeZone) {
      dt = dt.tz(timeZone);
    }
    if (!dt.isValid()) return '';

    if (format === 'd') {
      return dt.format(LOCAL_DATE_FORMAT);
    } else if (format === 't') {
      return dt.format(LOCAL_TIME_FORMAT);
    } else {
      return dt.format(`${LOCAL_DATE_FORMAT}, ${LOCAL_TIME_FORMAT}`);
    }
  }
}
