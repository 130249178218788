import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PaginationResult } from '~shared/data/interfaces/pagination-result.interface';
import { Pagination } from '~shared/data/models/pagination.model';
import { ColDisplayValue, TableColumn } from '~shared/data/models/table-column.model';
import { SortEvent } from '~shared/directives/sortable-column.directive';
import { PaginatedTableComponent } from '../paginated-table/paginated-table.component';
import * as moment from 'moment';
import { LOCAL_DATE_FORMAT, LOCAL_TIME_FORMAT } from '~shared/data/constants/date.constants';

@Component({
  template: ''
})
export class PaginationComponent<T> implements OnInit {
  @ViewChild(PaginatedTableComponent) table: PaginatedTableComponent;

  translationPrefix: string;
  visibleColumns = [];
  tableRows: T[] = [];
  tableColumns: TableColumn[] = [];
  pagination: Pagination;

  constructor(protected translateService: TranslateService) {}

  ngOnInit(): void {}

  /**
   * This method should be called whenever the table data changes to rebuild the table
   *
   */
  setupTable(paginationResult: PaginationResult<T>) {
    this.tableRows = paginationResult?.items;
    this.processPaginationResult(paginationResult);
    this.setupColumns();
  }

  getTranslatedColumnHeader(column: string) {
    return this.translateService.instant(`${this.translationPrefix}.LBL_${column.toUpperCase()}`);
  }

  reloadData() {
    throw new Error('This method needs to be implemented by the parent component.');
  }

  onPageChanged(page: number) {
    this.pagination.currentPage = page;
    this.reloadData();
  }

  onSortChanged(event: SortEvent) {
    const { column, direction } = event;
    this.pagination.sortColumn = column;
    this.pagination.sortDirection = direction;
  }

  processPaginationResult(result: PaginationResult<T>) {
    this.pagination = new Pagination(result);
  }

  getColumnDisplayValue(column: string): ColDisplayValue {
    return value => {
      const date = moment(value, moment.ISO_8601);
      if (date.isValid()) {
        return `${date.format(LOCAL_DATE_FORMAT)}, ${date.format(LOCAL_TIME_FORMAT)}`;
      }
      return value;
    };
  }

  private setupColumns() {
    if (this.tableRows?.length < 1) return;
    this.tableColumns = this.visibleColumns.map(
      col =>
        new TableColumn(col, this.getTranslatedColumnHeader(col), this.getColumnDisplayValue(col))
    );
  }
}
