import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AuthenticationService } from '../../data/services/authentication.service';

@Component({
  selector: 'cm-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit, OnDestroy {
  private redirectSub: Subscription;

  constructor(private auth: AuthenticationService, private router: Router) {}

  ngOnInit() {
    this.auth.handleAuthCallback();

    this.redirectSub = of(true)
      .pipe(delay(5000))
      .subscribe(() => this.router.navigateByUrl(''));
  }

  ngOnDestroy(): void {
    this.redirectSub.unsubscribe();
  }
}
