import { DeviceTelecomDto } from '~shared/data/models/device-telecom-dto.interface';

export class DeviceTelecom {
  mobileNumber: string;
  iccid: string;
  pin: string;
  puk: string;
  description: string;

  constructor(json: any) {
    this.mobileNumber = json.mobileNumber;
    this.iccid = json.iccid;
    this.pin = json.pin;
    this.puk = json.puk;
    this.description = json.description;
  }
}
