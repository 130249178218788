import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import * as OT from '@opentok/client';
import { AlertService } from '~shared/services/alert.service';
import { LoggingService } from '~shared/services/logging.service';

@Component({
  selector: 'cm-vonage-video-publisher',
  templateUrl: './vonage-video-publisher.component.html',
  styleUrls: ['./vonage-video-publisher.component.scss']
})
export class VonageVideoPublisherComponent implements AfterViewInit, OnDestroy {
  @ViewChild('publisherDiv') publisherDiv: ElementRef;
  @Input() session: OT.Session;
  @Output() streamPublished = new EventEmitter<boolean>();

  publisher: OT.Publisher;

  constructor(private alertService: AlertService, private logger: LoggingService) {}

  ngAfterViewInit() {
    this.publisher = OT.initPublisher(this.publisherDiv.nativeElement, {
      insertMode: 'append',
      width: '100%',
      height: '100%'
    });

    if (this.session) {
      if (this.session['isConnected']()) {
        this.publish();
      }
      this.session.on('sessionConnected', this.publish);
    }
  }

  ngOnDestroy(): void {
    this.logger.debug(`Unpublishing local stream`);
    this.session.off('sessionConnected', this.publish);
    this.session.unpublish(this.publisher);
  }

  publish = () => {
    this.session.publish(this.publisher, err => {
      if (err) {
        this.alertService.showError(err.message);
        this.streamPublished.emit(false);
      } else {
        this.streamPublished.emit(true);
      }
    });
  };
}
