<form novalidate [formGroup]="addressFormGroup">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
      <!-- Street Block -->
      <div class="form-group">
        <label class="control-label" for="inputStreet">{{
          'ADDRESS.LBL_STREET' | translate
        }}</label>
        <input
          formControlName="street"
          class="form-control"
          type="text"
          id="inputStreet"
          [ngClass]="{
            'is-invalid': submitted && ctrl.street.errors,
            'is-valid': ctrl.street.valid
          }"
        />
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
      <!-- City Block -->
      <div class="form-group">
        <label class="control-label" for="inputCity">{{ 'ADDRESS.LBL_CITY' | translate }}</label>
        <input
          formControlName="city"
          class="form-control"
          type="text"
          id="inputCity"
          [ngClass]="{
            'is-invalid': submitted && ctrl.city.errors,
            'is-valid': ctrl.city.valid
          }"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
      <!-- PostalCode Block -->
      <div class="form-group">
        <label class="control-label" for="inputPostalCode">{{
          'ADDRESS.LBL_POSTALCODE' | translate
        }}</label>
        <input
          formControlName="postalCode"
          class="form-control"
          type="text"
          id="inputPostalCode"
          [ngClass]="{
            'is-invalid': submitted && ctrl.postalCode.errors,
            'is-valid': ctrl.postalCode.valid
          }"
        />
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
      <!-- stateOrProvince Block -->
      <div class="form-group">
        <label class="control-label" for="inputStateOrProvince">{{
          'ADDRESS.LBL_STATEORPROVINCE' | translate
        }}</label>
        <input
          formControlName="stateOrProvince"
          class="form-control"
          type="text"
          id="inputStateOrProvince"
          [ngClass]="{
            'is-invalid': submitted && ctrl.stateOrProvince.errors,
            'is-valid': ctrl.stateOrProvince.valid
          }"
        />
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
      <!-- Country block -->
      <div class="form-group">
        <label class="control-label" for="inputCountry">{{
          'ADDRESS.LBL_COUNTRY' | translate
        }}</label>
        <cm-dropdown
          [disabled]="ctrl.country.disabled"
          [items]="countryDropdownItems"
          [invalid]="submitted && ctrl.country.invalid"
          [value]="getCountryDropdownItem(ctrl.country.value)"
          defaultLabel="COUNTRY.DROPDOWN_COUNTRY_DEFAULT"
          (valueSelected)="onCountryChanged($event.source)"
        ></cm-dropdown>
      </div>
    </div>
  </div>
</form>
