import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Alert, AlertService } from '~shared/services/alert.service';
import { LoadingService } from '~shared/services/loading.service';
import { VideoCallService } from '../videocall/video-call.service';
import { VideoChatClientError } from '../videocall/vonage-video-client/vonage-video-client.component';
import { ConfirmService } from '~shared/services/confirm-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from '~auth/data/services/authorization.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  isCondensed: boolean;
  isLoading = false;
  showVideoCall = false;
  isMinimized = true;
  isLocatedLeft = true;
  isLocatedTop = true;

  subscriptions: Subscription[] = [];
  alerts: Array<Alert>;

  constructor(
    private alertService: AlertService,
    private loadingService: LoadingService,
    private changeDetector: ChangeDetectorRef,
    private modalService: NgbModal,
    private videoCallService: VideoCallService,
    private confirmService: ConfirmService,
    private translateService: TranslateService,
    private authorizationService: AuthorizationService
  ) {}

  get videoCallSessionId() {
    return this.videoCallService.sessionId;
  }

  get videoCallToken() {
    return this.videoCallService.token;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.alertService.alerts$.subscribe(alerts => {
        this.alerts = alerts;
        this.changeDetector.detectChanges();
      })
    );
    this.subscriptions.push(
      this.loadingService.isLoading$.subscribe(loading => (this.isLoading = loading))
    );
    this.subscriptions.push(
      this.videoCallService.showVideoCall$.subscribe(show => (this.showVideoCall = show))
    );

    this.isCondensed = true;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  closeAlert(alert: Alert) {
    this.alertService.closeAlert(alert);
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }

  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    document.body.classList.toggle('sidebar-enable');
    document.body.classList.toggle('enlarged');
    this.isCondensed = !this.isCondensed;
  }

  callEnded(error?: VideoChatClientError) {
    if (error && error.name === 'OT_AUTHENTICATION_ERROR') {
      this.videoCallService
        .cancelVideoCallInvitation()
        .toPromise()
        .then(() => this.videoCallService.closeVideoCallDialog());
    } else {
      this.confirmService
        .confirm({
          message: this.translateService.instant('PROFILE.VIDEOCALL.MODALCALLENDED_MESSAGE'),
          title: this.translateService.instant('PROFILE.VIDEOCALL.MODALCALLENDED_TITLE')
        })
        .then(() => this.videoCallService.cancelVideoCallInvitation())
        .finally(() => this.videoCallService.closeVideoCallDialog());
    }
  }

  getResizeIcon() {
    return this.isMinimized ? 'mdi mdi-arrow-expand' : 'mdi mdi-arrow-collapse';
  }

  getVideoLocationIconX() {
    return this.isLocatedLeft ? 'mdi mdi-arrow-right-bold' : 'mdi mdi-arrow-left-bold';
  }

  getVideoLocationIconY() {
    return this.isLocatedTop ? 'mdi mdi-arrow-down-bold' : 'mdi mdi-arrow-up-bold';
  }

  isPatientView() {
    return this.authorizationService.isPatient();
  }
}
