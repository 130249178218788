<!-- Topbar Start -->
<div class="navbar-custom">
  <!-- Logo -->
  <div class="logo-box">
    <a href="/" class="logo text-center">
      <span class="logo-lg">
        <img src="assets/images/icon-white-text-logo.svg" alt="CM" />
      </span>
      <span class="logo-sm">
        <img src="assets/images/icon.png" alt="Chipmunk" />
      </span>
    </a>
  </div>

  <!-- Right Nav Menu -->
  <ul class="list-unstyled topnav-menu float-right mb-0">
    <li class="d-lg-block nav-link mr-3" style="background: transparent">
      <span class="align-middle">v{{ applicationVersion }}</span>
    </li>
    <!-- Notification icon -->
    <li class="dropdown notification-list" ngbDropdown [hidden]="true">
      <a
        class="nav-link dropdown-toggle  waves-effect waves-light"
        href="javascript: void(0);"
        role="button"
        aria-haspopup="false"
        aria-expanded="false"
        ngbDropdownToggle
        id="notificationDropdown"
      >
        <i class="mdi mdi-bell-outline noti-icon"></i>
        <span class="badge badge-danger rounded-circle noti-icon-badge">?</span>
      </a>
      <div
        class="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-lg"
        aria-labelledby="notificationDropdown"
        ngbDropdownMenu
      >
        <!-- item-->
        <div class="dropdown-item noti-title" ngbDropdownItem>
          <h5 class="m-0">
            <span class="float-right">
              <a href="javascript: void(0);" class="text-dark">
                <small>Clear All</small>
              </a> </span
            >{{ 'TOPBAR.NOTIFICATIONS' | translate }}
          </h5>
        </div>

        <div id="notification-items" class="slimscroll noti-scroll" appSlimScroll>
          <!-- item-->
          <a
            *ngFor="let notification of notificationItems"
            href="{{ notification.redirectTo }}"
            class="dropdown-item notify-item"
            ngbDropdownItem
          >
            <div
              class="notify-icon bg-soft-{{ notification.bgColor }} text-{{ notification.bgColor }}"
            >
              <i class="{{ notification.icon }}"></i>
            </div>
            <p class="notify-details">
              {{ notification.text }}
              <small class="text-muted">{{ notification.subText }}</small>
            </p>
          </a>
        </div>
        <!-- All-->
        <a
          href="javascript:void(0);"
          class="dropdown-item text-center text-primary notify-item notify-all"
        >
          {{ 'TOPBAR.VIEW_ALL' | translate }}
        </a>
      </div>
    </li>
    <!-- Language dropdown -->
    <li class="dropdown d-none d-lg-block" ngbDropdown>
      <a
        class="nav-link dropdown-toggle mr-0 waves-effect waves-light"
        href="javascript: void(0);"
        role="button"
        aria-haspopup="false"
        aria-expanded="false"
        ngbDropdownToggle
        id="langDropdown"
      >
        <span class="align-middle">{{ selectedLanguage.name | translate }}</span>
        <i class="ml-1 mdi mdi-chevron-down"></i>
      </a>
      <div
        class="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu"
        aria-labelledby="langDropdown"
        ngbDropdownMenu
      >
        <!-- item-->
        <a
          *ngFor="let language of languages"
          href="javascript:void(0);"
          class="dropdown-item notify-item"
          (click)="changeLanguage(language)"
          ngbDropdownItem
        >
          <span class="align-middle">{{ language.name | translate }}</span>
        </a>
      </div>
    </li>
    <!-- Login button -->
    <li class="waves-effect waves-light">
      <a class="nav-link" (click)="authService.login()" *ngIf="!authService.loggedIn">
        {{ 'COMMON.LOGIN' | translate }}
      </a>
    </li>
    <!-- User menu -->
    <li class="dropdown notification-list" ngbDropdown *ngIf="userProfile">
      <a
        class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
        ngbDropdownToggle
        id="profileDropdown"
        href="javascript: void(0);"
        role="button"
        aria-haspopup="false"
        aria-expanded="false"
      >
        <img [src]="userProfile.picture" alt="user-image" class="rounded-circle" />
        <span class="pro-user-name ml-1">
          {{ userProfile.name }} <i class="mdi mdi-chevron-down"></i>
        </span>
      </a>
      <div
        class="dropdown-menu dropdown-menu-right profile-dropdown"
        aria-labelledby="profileDropdown"
        ngbDropdownMenu
      >
        <!-- item-->
        <div>
          <p class="text-overflow m-0 text-right pr-3 pt-2 pb-0" style="font-size:0.8em;">
            v{{ applicationVersion }}
          </p>
        </div>
        <!-- item-->
        <div class="dropdown-header noti-title">
          <h6 class="text-overflow m-0">
            {{ 'USER.LBL_WELCOME' | translate }} {{ userProfile.nickname }}!
          </h6>
        </div>

        <!-- item-->
        <a
          href="javascript:void(0);"
          class="dropdown-item notify-item"
          (click)="downloadAcceptedAgreements()"
        >
          <i class="mdi mdi-file-download-outline mr-2"></i>
          <span>{{ 'COMMON.DOWNLOAD_AGREEMENTS' | translate }}</span>
        </a>

        <!-- item-->
        <a href="javascript:void(0);" class="dropdown-item notify-item" (click)="resetPassword()">
          <i class=" mdi mdi-lock-reset mr-2"></i>
          <span>{{ 'COMMON.RESET_PASSWORD' | translate }}</span>
        </a>

        <!-- item -->
        <a
          href="javascript:void(0);"
          class="dropdown-item notify-item"
          (click)="showFeedbackModal()"
          *ngIf="hasFeedbackProviderRole"
        >
          <i class=" mdi mdi-comment-text-outline mr-2"></i>
          <span>{{ 'FEEDBACK.LBL_REPORT' | translate }}</span>
        </a>

        <!-- item-->
        <a href="javascript:void(0);" class="dropdown-item notify-item" (click)="logout()">
          <i class=" mdi mdi-logout mr-2"></i>
          <span>{{ 'COMMON.LOGOUT' | translate }}</span>
        </a>
      </div>
    </li>
  </ul>
  <!-- end Topbar -->
</div>

<ng-template #feedbackModal let-modal>
  <div class="modal-header bg-secondary">
    <h4 class="modal-title text-white" id="modal-basic-title">
      {{ 'FEEDBACK.MODAL_TITLE' | translate }}
    </h4>
    <button
      type="button"
      class="close text-white"
      aria-label="Close"
      (click)="modal.dismiss('close')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group d-flex flex-column">
      <label style="margin-bottom: 20px;"> {{ 'FEEDBACK.LBL_DESCRIPTION' | translate }} </label>
      <textarea [(ngModel)]="feedbackText" class="form-control" style="height:150px;"></textarea>
    </div>
    <p style="font-size:12px; text-align: right; margin:0;">
      {{ 'FEEDBACK.MIN_CHARACTERS' | translate }} ({{ feedbackText.length }}/{{
        requiredFeedbackLength
      }})
    </p>
  </div>
  <div class="modal-footer">
    <div class="form-group d-flex flex-row-reverse">
      <button
        type="submit"
        class="btn btn-primary ml-2 btn-ok"
        (click)="modal.close('done')"
        [disabled]="isSubmitDisabled()"
      >
        {{ 'COMMON.OK' | translate }}
      </button>
      <button type="submit" class="btn btn-outline-primary" (click)="modal.dismiss('close')">
        {{ 'COMMON.CANCEL' | translate }}
      </button>
    </div>
  </div>
</ng-template>
