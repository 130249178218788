import { Injectable } from '@angular/core';
import { IAppConfig } from './data/interfaces/config.interface';

@Injectable()
export class AppConfig {
  public static settings: IAppConfig;

  constructor() {}

  public load() {
    const jsonFile = `/assets/config/config.${this.getHostEnvironment()}.json`;
    return new Promise<void>((resolve, reject) => {
      return fetch(jsonFile)
        .then(res => res.json())
        .then((result: IAppConfig) => {
          AppConfig.settings = <IAppConfig>result;
          resolve();
        })
        .catch((response: any) => {
          reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
        });
    });
  }

  private getHostEnvironment() {
    if (location.host.startsWith('dashboard.chipmunkhealth.com')) {
      return 'prod';
    } // else if (location.host.startsWith('dashboard-dev.chipmunkhealth.com')) {
    //   return 'dev';
    // }
    // return 'loc';
    return 'dev';
  }
}
