import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter } from 'rxjs/operators';

@Component({
  selector: 'cm-search',
  templateUrl: './cm-search.component.html',
  styleUrls: ['./cm-search.component.scss']
})
export class CmSearchComponent implements OnInit {
  @ViewChild('searchInput', { static: true })
  searchInput: ElementRef;

  @Output() searchTermChange: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    merge(
      fromEvent(this.searchInput.nativeElement, 'search'),
      fromEvent(this.searchInput.nativeElement, 'keyup')
    )
      .pipe(
        filter((x: any) => {
          return x.key !== 'Enter';
        }),
        map(() => {
          return this.searchInput.nativeElement.value;
        }),
        // Time in milliseconds between key events
        debounceTime(300),
        // If previous query is diffent from current
        distinctUntilChanged()
      )
      // subscription for response
      .subscribe((term: string) => {
        this.searchTermChange.emit(term);
      });
  }
}
