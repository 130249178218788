export class MeasurementModel {
  name: string;
  value: string;
  unit: string;
  thresholdStatus: string;
  time: string;

  constructor(json: any) {
    this.name = json.name;
    this.value = json.value;
    this.unit = json.unit;
    this.thresholdStatus = json.thresholdStatus;
    this.time = json.time;
  }

  getAlertClass() {
    switch (this.thresholdStatus) {
      case 'lowExceeded':
      case 'highExceeded':
        return 'warning';
      case 'highestExceeded':
      case 'lowestExceeded':
        return 'danger';
      case 'missed':
        return 'info';
      case 'normal':
      default:
        return '';
    }
  }
}
