import { Patient } from './patient.model';
import { Organization } from '~shared/data/models/organization.model';

export class UserDto {
  id: string;
  type: string;
  email: string;
  firstName: string;
  lastName: string;
  isDeactivated: boolean;
  correspondenceLanguageCode: string;
  customerNumber: string;
  hasLoginAccount: boolean;
  patient: Patient;
  roles: string[];
  organizationId: string;
  organization: Organization;
  hasAcceptedAllAgreements: boolean;
  deviceId: string;
  agreementResponseMode: string;

  constructor(json: any) {
    this.id = json.id;
    this.email = json.email;
    this.firstName = json.firstName;
    this.lastName = json.lastName;
    this.isDeactivated = json.isDeactivated;
    this.patient = new Patient(json.patient || {});
    this.roles = json.roles;
    this.type = json.type;
    this.organizationId = json.organizationId;
    this.organization = json.organization ? new Organization(json.organization) : null;
    this.correspondenceLanguageCode = json.correspondenceLanguageCode;
    this.customerNumber = json.customerNumber;
    this.hasLoginAccount = json.hasLoginAccount;
    this.hasAcceptedAllAgreements = json.hasAcceptedAllAgreements;
    this.deviceId = json.deviceId;
    this.agreementResponseMode = json.agreementResponseMode;
  }
}
