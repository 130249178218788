import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '~shared/services/alert.service';
import { ApplicationInsightsService } from '~shared/services/app-insights.service';
import { LoggingService } from '~shared/services/logging.service';
import { BackendError } from '~shared/data/models/backend-error.model';
import { BackendValidationError } from '~shared/data/models/validation-backend-error.model';

@Injectable({ providedIn: 'root' })
export class CustomErrorHandler implements ErrorHandler {
  constructor(private injector: Injector, private translateService: TranslateService) {}

  handleError(error: Error | HttpErrorResponse) {
    const notificationService = this.injector.get(AlertService);
    const appInsights = this.injector.get(ApplicationInsightsService);
    const logger = this.injector.get(LoggingService);

    if (!navigator.onLine) {
      // No Internet connection
      return notificationService.showAlert({
        type: 'danger',
        message: this.translateService.instant(`ERROR.INTERNET_INTERRUPTED`)
      });
    }

    if (error instanceof HttpErrorResponse) {
      // Server error happened

      //Slight workaround for not fixable 500 errors
      if (error.status === 500 && error.error.error === "Value cannot be null. (Parameter 'uri')") {
        console.log('Dit is een veelvoorkomende error: ', error);
      } else if (error.status === 400) {
        // 400 usually means something developer related. Check for validation error or generic error.
        let message = '';
        if (!error.error.errors) {
          message = new BackendError(error.error).toString();
        } else {
          message = new BackendValidationError(error.error).toString();
        }
        if (message.length === 0) message = error.message;
        logger.error(message, error);
        // Show notification to the user
        return notificationService.showAlert({
          title: error.error.title || null,
          type: 'danger',
          message: `${message}`
        });
      } else if (error.status === 500) {
        const message = `${error.status} - ${error.statusText}`;
        logger.error(message, error);
        // Show notification to the user
        return notificationService.showAlert({
          title: this.translateService.instant(`ERROR.UNEXPECTED_ERROR`),
          type: 'danger',
          message: `${message}`
        });
      } else {
        // Http Error
        logger.error(error.message, error);
        appInsights.logException({
          exception: error,
          severityLevel: error.status >= 500 ? 4 : 3
        });

        // Show notification to the user
        return notificationService.showAlert({
          type: 'danger',
          message: `${error.message}`
        });
      }
    } else {
      // Client Error Happened
      // Send the error to the server and then redirect the user to the page with all the info
      logger.error(error.message, error);
    }
  }
}
