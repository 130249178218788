import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ImgFallbackModule } from 'ngx-img-fallback';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AuthenticationModule } from '~auth/authentication.module';
import { AuthenticationService } from '~auth/data/services/authentication.service';
import { DevicesService } from '~core/devices/data/services/devices.service';
import { OrganizationsService } from '~core/organizations/data/services/organizations.service';
import { PatientsService } from '~core/users/data/services/patients.service';
import { UsersService } from '~core/users/data/services/users.service';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { AlertComponent } from './components/alert/alert.component';
import { CmCheckboxComponent } from './components/cm-checkbox/cm-checkbox.component';
import {
  CmConfirmModalComponent,
  ConfirmTemplateDirective
} from './components/cm-confirm-modal/cm-confirm-modal.component';
import { CmDataCardComponent } from './components/cm-data-card/cm-data-card.component';
import { CmDataTableComponent } from './components/cm-data-table/cm-data-table.component';
import { CmDropdownComponent } from './components/cm-dropdown/cm-dropdown.component';
import { EditableComponent } from './components/editable/editable.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { PortletComponent } from './components/portlet/portlet.component';
import { CountToDirective } from './directives/count-to.directive';
import { EditModeDirective } from './directives/edit-mode.directive';
import { FocusableDirective } from './directives/focusable.directive';
import { RippleEffectDirective } from './directives/ripple.directive';
import { SaveOnEnterDirective } from './directives/save-on-enter.directive';
import { ViewModeDirective } from './directives/view-mode.directive';
import { LoadingInterceptor } from './loading-interceptor';
import { MomentDateFormatter } from './ngb-date-formatter';
import {
  CapitalizePipe,
  LocalDatePipe,
  LocalNumberPipe,
  NumberWithCommasPipe,
  PluralPipe,
  RoundPipe,
  SentenceCasePipe,
  TimingPipe,
  LocalTimePipe
} from './pipes';
import { AlertService } from './services/alert.service';
import { ApplicationInsightsService } from './services/app-insights.service';
import { ConfirmService } from './services/confirm-modal.service';
import { ImagesService } from './services/images.service';
import { LanguageService } from './services/language.service';
import { LoadingService } from './services/loading.service';
import { LoggingService } from './services/logging.service';
import { FeedbackService } from './services/feedback.service';
import { SortableColumnDirective } from './directives/sortable-column.directive';
import { PaginatedTableComponent } from './components/paginated-table/paginated-table.component';
import { CmModalComponent } from './components/cm-modal/cm-modal.component';
import { CmSearchComponent } from './components/cm-search/cm-search.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { CmInputAutocompleteComponent } from './components/cm-input-autocomplete/cm-input-autocomplete.component';
import { FileDownloadService } from './services/file-download.service';
import { CustomDropzoneImagePreviewComponent } from './components/custom-dropzone-image-preview/custom-dropzone-image-preview.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { EditorModule } from '@tinymce/tinymce-angular';

import { TableModule } from 'primeng/table';
import { SplitButtonModule } from 'primeng/splitbutton';
import { LocalDateTimePipe } from '~shared/pipes/local-date-time.pipe';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';

// A pipe takes in data as input and transforms it to a desired output. See https://angular.io/guide/pipes
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  SentenceCasePipe,
  LocalNumberPipe,
  LocalDatePipe,
  LocalTimePipe,
  LocalDateTimePipe
];

// Angular modules that are used by pretty much every module.
const COMMON_ANGULAR_MODULES = [FormsModule, ReactiveFormsModule, CommonModule, RouterModule];

// 3rd party modules that are used by multiple modules.
const COMMON_THIRD_PARTY_MODULES = [
  TranslateModule,
  NgbModule,
  AgGridModule,
  NgBootstrapFormValidationModule,
  NgxMaskModule,
  ImgFallbackModule,
  UiSwitchModule,
  NgApexchartsModule,
  NgxPageScrollCoreModule,
  NgxPageScrollModule,
  NgxDropzoneModule,
  PdfViewerModule,
  EditorModule
];

const PRIMENG_MODULES = [TableModule, SplitButtonModule, DropdownModule, CheckboxModule];

// Services that can be used by multiple modules.
// NOTE: These services are pure singletons because they will be injected at root level!
const SHARED_SERVICES = [
  AlertService,
  ApplicationInsightsService,
  LoadingService,
  LoggingService,
  AuthenticationService,
  ConfirmService,
  ImagesService,
  LanguageService,
  FeedbackService,
  FileDownloadService
];

const FEATURE_SERVICES = [UsersService, PatientsService, OrganizationsService, DevicesService];

// Components that can be used by multiple modules
const SHARED_COMPONENTS = [
  EditableComponent,
  AlertComponent,
  LoadingSpinnerComponent,
  CmDataCardComponent,
  PageTitleComponent,
  CmConfirmModalComponent,
  CmDataTableComponent,
  AddressFormComponent,
  PortletComponent,
  CmCheckboxComponent,
  CmDropdownComponent,
  PaginatedTableComponent,
  CmModalComponent,
  CmSearchComponent,
  PaginationComponent,
  CmInputAutocompleteComponent,
  CustomDropzoneImagePreviewComponent
];

// Directives that can be used by multiple modules
const SHARED_DIRECTIVES = [
  EditModeDirective,
  ViewModeDirective,
  SaveOnEnterDirective,
  FocusableDirective,
  ConfirmTemplateDirective,
  CountToDirective,
  RippleEffectDirective,
  SortableColumnDirective
];

//////////////////////////////////////////////////////////////////////////////////////////////////
@NgModule({
  declarations: [...PIPES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES],
  providers: [...PIPES],
  imports: [
    ...COMMON_ANGULAR_MODULES,
    ...COMMON_THIRD_PARTY_MODULES,
    ...PRIMENG_MODULES,
    AuthenticationModule
  ],
  exports: [
    ...PIPES,
    ...COMMON_ANGULAR_MODULES,
    ...SHARED_COMPONENTS,
    ...SHARED_DIRECTIVES,
    ...COMMON_THIRD_PARTY_MODULES,
    ...PRIMENG_MODULES,
    AuthenticationModule
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ...SHARED_SERVICES,
        ...FEATURE_SERVICES,
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        { provide: NgbDateParserFormatter, useClass: MomentDateFormatter }
      ]
    };
  }
}
