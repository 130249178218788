import { PhoneNumber } from '../../../users/data/models/phone-number.model';
import { UserDto } from '~core/users/data/models/user-dto.model';

export class PatientPersonalia {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  street: string;
  postalCode: string;
  city: string;
  country: string;
  customerNumber: string;
  isActive: boolean;
  serviceStartDate: Date;
  dateOfBirth: Date;
  emergencyContactName: string;
  emergencyContactEmail: string;
  emergencyContactPhoneNumber: string;

  constructor(json: UserDto) {
    this.id = json.id;
    this.customerNumber = json.customerNumber;
    this.firstName = json.firstName;
    this.lastName = json.lastName;
    this.email = json.email;

    if (json.patient) {
      this.street = json.patient.address.street;
      this.postalCode = json.patient.address.postalCode;
      this.city = json.patient.address.city;
      this.country = json.patient.address.country;
      this.serviceStartDate = json.patient.serviceStartDate;
      this.dateOfBirth = json.patient.dateOfBirth;
      if (json.patient.phoneNumbers) {
        json.patient.phoneNumbers.forEach((number: any) => {
          const phoneNumber = new PhoneNumber(number);
          this[
            `phoneNumber ${json.patient.phoneNumbers.indexOf(number) + 1}`
          ] = phoneNumber.toString();
        });
      }
      this.emergencyContactName = json.patient.emergencyContactName;
      this.emergencyContactEmail = json.patient.emergencyContactEmail;
      this.emergencyContactPhoneNumber = json.patient.emergencyContactPhoneNumber;
    }
    this.isActive = !json.isDeactivated;
  }

  get name(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
