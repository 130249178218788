import {
  PhoneNumberType,
  NotificationMethod,
  UserGender
} from '~core/users/data/constants/user.constants';
import { ICountry } from '~shared/data/interfaces/country.interface';

export const countries: ICountry[] = [
  { name: 'Netherlands', code: '31' },
  { name: 'Canada', code: '1' },
  { name: 'Germany', code: '49' }
];

export const countryFlags = {
  Netherlands: 'assets/images/flags/flag-nl.svg',
  Canada: 'assets/images/flags/flag-ca.svg',
  Germany: 'assets/images/flags/flag-de.svg'
};

export const languages = [
  { name: 'English', twoLetterCode: 'en' },
  { name: 'Nederlands', twoLetterCode: 'nl' },
  { name: 'Deutsch', twoLetterCode: 'de' }
];

export const genders: UserGender[] = ['Male', 'Female', 'Other', 'Unknown'];

export const notificationMethods: NotificationMethod[] = ['None', 'Call', 'Text', 'Email'];

export const phoneNumberTypes: PhoneNumberType[] = ['Home', 'Work', 'Mobile', 'Fax'];

export const organizationContactTypes = [
  {
    label: 'ORGANISATION.CONTACT_MEDICAL',
    value: 'Medical'
  },
  {
    label: 'ORGANISATION.CONTACT_MANAGERIAL',
    value: 'Managerial'
  },
  {
    label: 'ORGANISATION.CONTACT_FINANCIAL',
    value: 'Financial'
  }
];

export const comorbidities = [
  {
    label: 'PATIENT.COMORBIDITY.DIABETES',
    value: 'Diabetes'
  },
  {
    label: 'PATIENT.COMORBIDITY.COPD',
    value: 'COPD'
  },
  {
    label: 'PATIENT.COMORBIDITY.AFIB',
    value: 'Afib'
  },
  {
    label: 'PATIENT.COMORBIDITY.STROKE',
    value: 'Stroke'
  },
  {
    label: 'PATIENT.COMORBIDITY.HEART_FAILURE',
    value: 'Heart failure'
  },
  {
    label: 'PATIENT.COMORBIDITY.HYPERTENSION',
    value: 'Hypertension'
  },
  {
    label: 'PATIENT.COMORBIDITY.LIVER_DISEASE',
    value: 'Liver disease'
  },
  {
    label: 'PATIENT.COMORBIDITY.ANXIETY',
    value: 'Anxiety'
  },
  {
    label: 'PATIENT.COMORBIDITY.CANCER',
    value: 'Cancer'
  },
  {
    label: 'PATIENT.COMORBIDITY.RHEUMATOID_ARTHRITIS',
    value: 'Rheumatoid arthritis'
  },
  {
    label: 'PATIENT.COMORBIDITY.OBESITY',
    value: 'Obesity'
  },
  {
    label: 'PATIENT.COMORBIDITY.OTHER',
    value: 'Other'
  }
];

export const serviceLevels = [
  { label: 'PATIENT.LBL_LEVEL_ONE', value: 1 },
  { label: 'PATIENT.LBL_LEVEL_TWO', value: 2 }
];

export const primaryDiagnoses = [
  {
    label: 'PATIENT.DIAGNOSES.DIABETES',
    value: 'Diabetes'
  },
  {
    label: 'PATIENT.DIAGNOSES.COPD',
    value: 'COPD'
  },
  {
    label: 'PATIENT.DIAGNOSES.STROKE',
    value: 'Stroke'
  },
  {
    label: 'PATIENT.DIAGNOSES.CHF',
    value: 'CHF'
  },
  {
    label: 'PATIENT.DIAGNOSES.OTHER',
    value: 'Other'
  }
];

export const telemetryRanges = [
  {
    label: 'USER.LBL_DAYS.ONE',
    value: 1
  },
  {
    label: 'USER.LBL_DAYS.THREE',
    value: 3
  },
  {
    label: 'USER.LBL_DAYS.SEVEN',
    value: 7
  },
  {
    label: 'USER.LBL_DAYS.FOURTEEN',
    value: 14
  },
  {
    label: 'USER.LBL_DAYS.THIRTY',
    value: 30
  }
];

export const measurementMoments = [
  {
    label: 'MEASUREMENT.PERIODS.MOMENT_1',
    value: 0
  },
  {
    label: 'MEASUREMENT.PERIODS.MOMENT_2',
    value: 1
  },
  {
    label: 'MEASUREMENT.PERIODS.MOMENT_3',
    value: 2
  },
  {
    label: 'MEASUREMENT.PERIODS.MOMENT_4',
    value: 3
  }
];

export const loanAgreement = [
  {
    label: 'USER.AGREEMENTS.CHECKBOX_AGREED',
    value: null
  }
];

export const dataConsent = [
  {
    label: 'USER.AGREEMENTS.CHECKBOX_AGREED',
    value: null
  }
];

export const userAgreement = [
  {
    label: 'USER.AGREEMENTS.CHECKBOX_AGREED',
    value: null
  }
];
