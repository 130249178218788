<div ngbDropdown class="dropdown cm-dropdown">
  <div class="btn-group" style="width: 100%;">
    <button
      type="button"
      class="btn dropdown-toggle waves-effect"
      [ngClass]="invalid ? 'btn-outline-danger' : 'btn-outline-tertiary'"
      ngbDropdownToggle
      [disabled]="disabled"
    >
      <img
        *ngIf="value?.image"
        [src]="value.image"
        alt="{{ value.label | translate }}"
        class="mr-2"
        height="18"
      />
      <i *ngIf="value?.icon" [class]="value?.icon"></i>
      <span class="align-middle">
        {{ value?.label || defaultLabel | translate }}
      </span>
      <i class="mdi mdi-chevron-down"></i>
    </button>
    <button
      type="button"
      class="btn btn-outline-secondary"
      [title]="'COMMON.CLEAR' | translate"
      [hidden]="!showClearButton"
      [disabled]="disabled"
      (click)="clear()"
    >
      <i class="mdi mdi-close"></i>
    </button>
  </div>
  <div class="dropdown-menu" ngbDropdownMenu>
    <a
      *ngFor="let item of items"
      class="dropdown-item"
      href="javascript: void(0);"
      (click)="onSelected(item)"
    >
      <img
        *ngIf="item.image"
        [src]="item.image"
        alt="{{ item.label | translate }}"
        class="mr-2"
        height="18"
      />
      <i *ngIf="item?.icon" [class]="item?.icon"></i>
      <span class="align-middle">{{ item.label | translate }}</span>
    </a>
  </div>
</div>
