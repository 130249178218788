import { PhoneNumberType } from '~core/users/data/constants/user.constants';

export class PhoneNumber {
  id: string;
  numberType: PhoneNumberType;
  number: string;

  constructor(json: any) {
    this.id = json.id || '';
    this.numberType = json.numberType || '';
    this.number = json.number || '';
  }

  toString(): string {
    return `${this.number} (${this.numberType})`;
  }
}
