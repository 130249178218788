import { NgModule } from '@angular/core';

import { VideoCallRoutingModule } from './videocall-routing.module';
import { SharedModule } from '~shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PatientVideoChatHostComponent } from './patient-video-chat-host/patient-video-chat-host.component';
import { VonageVideoClientComponent } from './vonage-video-client/vonage-video-client.component';
import { VonageVideoSubscriberComponent } from './vonage-video-client/vonage-video-subscriber/vonage-video-subscriber.component';
import { VonageVideoPublisherComponent } from './vonage-video-client/vonage-video-publisher/vonage-video-publisher.component';

@NgModule({
  imports: [
    TranslateModule.forChild({
      extend: true
    }),
    SharedModule,
    VideoCallRoutingModule
  ],
  declarations: [
    PatientVideoChatHostComponent,
    VonageVideoClientComponent,
    VonageVideoSubscriberComponent,
    VonageVideoPublisherComponent
  ],
  exports: [VonageVideoClientComponent]
})
export class VideoCallModule {}
