<cm-vonage-video-client
  [sessionId]="sessionId"
  [token]="token"
  (callEnded)="onCallEnded()"
  *ngIf="!callEnded"
></cm-vonage-video-client>
<div *ngIf="callEnded">
  <p *ngIf="error" class="text-center">
    {{ errorLabel }}
  </p>
  <div *ngIf="!error">
    <h5 class="text-center">{{ sessionEndedLabel }}</h5>
  </div>
</div>
