import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { IUserSummaryDto, UserSummary } from '~core/dashboard/data/models/user-summary.model';
import { VitalsDataSet } from '~core/dashboard/data/models/vitals.model';
import { DeviceType } from '~core/devices/data/models/device-type.model';
import { DevicesService } from '~core/devices/data/services/devices.service';
import { MeasurementSchemeDto } from '~core/user-profile/data/models/measurement-scheme-dto.model';
import { MeasurementScheme } from '~core/user-profile/data/models/measurement-scheme.model';
import { PatientLogItem } from '~core/user-profile/data/models/patient-log.model';
import { PatientPersonalia } from '~core/user-profile/data/models/patient-personalia.model';
import { PatientProfile } from '~core/user-profile/data/models/patient-profile.model';
import { AttachmentListResultDto } from '~core/user-profile/data/models/profile-attachment-v2.model';
import { ProfileAttachment } from '~core/user-profile/data/models/profile-attachment.model';
import { ISurveyDto, Survey } from '~core/user-profile/data/models/survey.model';
import { Threshold } from '~core/user-profile/data/models/thresholds.model';
import { UserSurveyDto } from '~core/user-profile/data/models/user-survey.model';
import { PatientFormModel } from '~core/users/components/patient-form/patient-form.model';
import { Patient } from '~core/users/data/models/patient.model';
import { TelemetryDataSet } from '~core/users/data/models/telemetry.model';
import { AppConfig } from '~shared/app-config';
import {
  ENDPOINT_CSV,
  ENDPOINT_DEVICES,
  ENDPOINT_MEASUREMENTS,
  ENDPOINT_PATIENTS,
  ENDPOINT_SURVEYS,
  ENDPOINT_USERSURVEYS,
  ENDPOINT_VIDEOCONFERENCES,
  ENDPOINT_VITALS
} from '~shared/data/constants/api.constants';
import { ICheckbox } from '~shared/data/interfaces/checkbox.interface';
import { PaginationResult } from '~shared/data/interfaces/pagination-result.interface';
import { FileDownloadService } from '~shared/services/file-download.service';
import { LoggingService } from '~shared/services/logging.service';
import { CmUtils } from '~shared/util/cm-utils';
import { User } from '../models/user.model';
import { VideoCallItemDto } from '../models/video-call-item.dto';
import { UsersService } from './users.service';

@Injectable({ providedIn: 'root' })
export class PatientsService {
  private availableSurveyCache$: Observable<Survey[]>;

  constructor(
    private http: HttpClient,
    private devicesService: DevicesService,
    private userService: UsersService,
    private fileDownloadService: FileDownloadService,
    private loggingService: LoggingService,
    protected translateService: TranslateService
  ) {}

  getTelemetryData(
    userId: string,
    range: number,
    deviceType?: string
  ): Observable<TelemetryDataSet[]> {
    let availableTypes: Array<DeviceType> = [];
    let queryParams = new HttpParams();
    const startTimestamp = moment()
      .utc()
      .add(1, 'day')
      .startOf('day')
      .subtract(range || AppConfig.settings.api.telemetryRange, 'days')
      .toISOString();
    queryParams = queryParams.append('timestamp', `ge${startTimestamp}`);
    if (deviceType) queryParams = queryParams.append('deviceType', `${deviceType}`);

    return this.devicesService.getAvailableDeviceTypes().pipe(
      tap(types => (availableTypes = types)),
      switchMap(() =>
        this.http.get<any>(
          `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/${userId}/${ENDPOINT_MEASUREMENTS}`,
          { params: queryParams }
        )
      ),
      map(result => result.map(data => new TelemetryDataSet(data))),
      map(result => this.mapVitalPropsToDataSets(result, availableTypes))
    );
  }

  private mapVitalPropsToDataSets(dataSets: TelemetryDataSet[], availableTypes: DeviceType[]) {
    return dataSets.map(dataSet => {
      try {
        const deviceType = availableTypes.find(type => type.name === dataSet.deviceType);
        const props = deviceType.vitalProperties;
        dataSet.thresholdProperties = props
          .map(prop => ({
            name: prop.name.charAt(0).toLowerCase() + prop.name.slice(1),
            unit: prop.unit,
            sortOrdinal: prop.sortOrdinal
          }))
          .sort((a, b) => b.sortOrdinal - a.sortOrdinal);
        return dataSet;
      } catch (error) {
        return dataSet;
      }
    });
  }

  getVitals() {
    return this.http.get(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/Vitals?pageSize=${AppConfig.settings.api.dashboardPageSize}`
    );
  }

  setVitalsSeen(userId: string): Observable<any> {
    return this.http.put<any>(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/${userId}/${ENDPOINT_VITALS}/reset`,
      null
    );
  }

  getUserSummary(userId: string): Observable<UserSummary> {
    return this.http
      .get<IUserSummaryDto>(
        `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/${userId}/${ENDPOINT_VITALS}`
      )
      .pipe(map(res => new UserSummary(res)))
      .pipe(
        tap(res => {
          this.loggingService.debug('PatientService -> getPatientVitals()', res);
        })
      );
  }

  getGroupedDeviceTypes(deviceTypes: DeviceType[], vitals?: VitalsDataSet[]): Map<any, any> {
    deviceTypes.sort((a, b) => a.sortOrdinal - b.sortOrdinal);
    const deviceTypesGroupedByName = CmUtils.groupBy(deviceTypes, x => x.name);
    const arrayMap = Array.from(
      deviceTypesGroupedByName,
      ([deviceType, value]: [string, DeviceType[]]) => {
        const vitalProps = value[0].thresholdProperties.sort(
          (a, b) => a.sortOrdinal - b.sortOrdinal
        );
        const vital = vitals?.find(x => x.deviceType === deviceType);
        if (vital) {
          vital.measurements.sort((a, b) => {
            const indexA = vitalProps.findIndex(x => x.name === a.name);
            const indexB = vitalProps.findIndex(x => x.name === b.name);
            return indexA - indexB;
          });
          const groupedVitals = CmUtils.groupBy(vital.measurements, x => x.unit);
          return [deviceType, groupedVitals];
        } else {
          const groupedVitals = CmUtils.groupBy(vitalProps, x => x.unit);
          return [deviceType, groupedVitals];
        }
      }
    );
    return new Map(arrayMap as any[]);
  }

  addLogEntry(userId: string, logEntry: PatientLogItem): Observable<any> {
    return this.http.post(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/${userId}/logitems`,
      logEntry
    );
  }

  getAvailableLogCategories(userId: string, filter?: string) {
    return this.http.get<string[]>(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/${userId}/logitems/categories`
    );
  }

  /**
   * Composes measurement thresholds collection using thresholds in patient record, combined with
   * new device/property thresholds that are not yet configured.
   * @param patient
   * @param deviceTypes
   */
  getMeasurementThresholds(patient: Patient, deviceTypes: DeviceType[]): Threshold[] {
    if (!patient?.devices) return [];

    const thresholdProperties = deviceTypes.reduce((arr, deviceType) => {
      for (const thresholdProperty of deviceType.thresholdProperties) {
        const existing = patient.measurementThresholds.find(
          mt => mt.propertyName === thresholdProperty.name && mt.deviceType === deviceType.name
        );
        arr.push(
          existing ||
            new Threshold({
              propertyName: thresholdProperty.name,
              deviceType: deviceType.name
            })
        );
      }
      return arr;
    }, <Threshold[]>[]);

    return thresholdProperties;
  }

  getUserProfile(userId: string) {
    return forkJoin([
      this.userService.get(userId),
      this.devicesService.getAvailableDeviceTypes()
    ]).pipe(
      map(([user, deviceTypes]) => {
        const profile = {
          organization: user.organization,
          patient: user.patient,
          personalia: new PatientPersonalia(user),
          thresholdPropertiesGroupedPerDeviceType: this.getGroupedDeviceTypes(deviceTypes)
        };
        return new PatientProfile(profile);
      })
    );
  }

  createPatientFormModel(userModel: User, deviceTypes: DeviceType[]): PatientFormModel {
    return new PatientFormModel(userModel.patient, deviceTypes);
  }

  mapPatientFormToModel(patientFormModel: PatientFormModel, formValue: any): Patient {
    const patient: Patient = { ...patientFormModel.patientModel, ...formValue };

    // fix Date of birth format
    const dateOfBirth = formValue.dateOfBirth;
    const formattedDOB = moment.utc([dateOfBirth.year, dateOfBirth.month - 1, dateOfBirth.day]);
    patient.dateOfBirth = formattedDOB.toDate();

    patient.address.country = formValue.address.country.name || patient.address.country;
    patient.deviceIds = patient.devices as string[];
    patient.accessControllerUrl = formValue.accessController;
    patient.accessControllerIntegrationUrl =
      formValue.accessController === 'http://chipmunkac01.chipmunkhealth.com'
        ? 'https://philips-cassia-integration-ac01.eu1.phsdp.com/routerconfig/'
        : 'https://philips-cassia-integration-ac04.eu1.phsdp.com/routerconfig/';
    delete patient.devices;
    if (formValue.medicalDiagnosis) {
      patient.medicalDiagnosis.primaryDiagnoses = formValue.medicalDiagnosis.primaryDiagnoses
        .map((val: ICheckbox<string>) => {
          if (val.selected) return val.value;
        })
        .filter(checked => checked);

      patient.medicalDiagnosis.primaryComorbidities = formValue.medicalDiagnosis.primaryComorbidities
        .map((val: ICheckbox<string>) => {
          if (val.selected) return val.value;
        })
        .filter(checked => checked);
    }
    return patient;
  }

  updateMeasurementThresholds(userId: string, thresholds: Threshold[]) {
    return this.http.put(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/${userId}/thresholds`,
      { measurementThresholds: thresholds }
    );
  }

  updateMeasurementScheme(userId: string, measurementSchemes: MeasurementScheme[]) {
    const measurementSchemesDto = measurementSchemes.map(
      scheme => new MeasurementSchemeDto(scheme)
    );
    return this.http.put(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/${userId}/schedules`,
      { measurementSchedules: measurementSchemesDto }
    );
  }

  getAttachmentList(userId: string) {
    return this.http.get<AttachmentListResultDto>(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/v2/${userId}/attachments`
    );
  }

  getAvailableSurveys(): Observable<ISurveyDto[]> {
    if (this.availableSurveyCache$) return this.availableSurveyCache$;
    return this.http.get<ISurveyDto[]>(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_SURVEYS}`);
  }

  getUserSurveys(
    userId?: string,
    searchTerm: string = null,
    page: number = 1,
    pageSize: number = AppConfig.settings.api.pageSize
  ): Observable<PaginationResult<UserSurveyDto>> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append(
      'pageSize',
      `${pageSize ? pageSize : AppConfig.settings.api.pageSize}`
    );
    if (searchTerm) queryParams = queryParams.append('text', `${searchTerm}`);
    if (userId) queryParams = queryParams.append('userId', `${userId}`);
    if (page) queryParams = queryParams.append('currentPage', `${page}`);
    return this.http.get<PaginationResult<UserSurveyDto>>(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_SURVEYS}/${ENDPOINT_USERSURVEYS}`,
      {
        params: queryParams
      }
    );
  }

  sendSurveyToPatient(userProfile: PatientProfile, templateId: string): Observable<any> {
    const { id } = userProfile.personalia;
    const body = {
      userId: id,
      templateId
    };
    return this.http.post<ISurveyDto[]>(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_SURVEYS}/InitializeUserTemplate`,
      body
    );
  }

  /**
   * Retrieves an URI including access token for the given user and attachment
   *
   * @param {string} userId
   * @param {ProfileAttachment} attachment
   * @param {('Read' | 'Create' | 'Delete')} permission
   * @returns {Observable<any>}
   * @memberof PatientsService
   */
  retrieveAttachmentsAccessToken(
    userId: string,
    fileName: string,
    fileType: string,
    permission: 'Read' | 'Create' | 'Delete'
  ): Observable<any> {
    return this.http.post(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/${userId}/attachments/accesstoken`,
      {
        tokenPermission: permission,
        fileName: fileName,
        contentType: fileType
      }
    );
  }

  uploadAttachmentToStorage(uri: string, file: File, folderName: string) {
    const headers = new HttpHeaders({
      'x-ms-type': 'file',
      'x-ms-blob-type': 'BlockBlob',
      'x-ms-version': '2015-02-21',
      'x-ms-blob-content-disposition': `attachment; filename="${folderName}${file.name}"`,
      'Content-Type': file.type
    });
    return this.http.put(uri, file, { headers });
  }

  deleteAttachmentFromStorage(uri: string) {
    return this.http.delete(uri);
  }

  exportToCsv(userId: string, startDate: Date) {
    let queryParams = new HttpParams();
    const isoTimestamp = moment(startDate)
      .utc()
      .startOf('day')
      .toISOString();
    queryParams = queryParams.append('timestamp', `ge${isoTimestamp}`);

    this.fileDownloadService.downloadFromUrl(
      'measurements.zip',
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/${userId}/${ENDPOINT_CSV}`,
      { params: queryParams }
    );
  }

  deleteAttachmentFolder(userId: string, folderName: string): Observable<any> {
    return this.http.delete(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/${userId}/attachments/directories/${folderName}`,
      {}
    );
  }

  createAttachmentFolder(userId: string, folderName: string): Observable<any> {
    return this.http.post(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/${userId}/attachments/directories/${folderName}`,
      {}
    );
  }

  createMeasurement(userId: string, deviceId: string, properties: { [key: string]: any }) {
    return this.http.post(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/${userId}/measurements`,
      {
        userAccountId: userId,
        deviceId,
        properties: Object.keys(properties).reduce((acc, key) => {
          acc[key] = properties[key]?.toString();
          return acc;
        }, {})
      }
    );
  }

  createMeasurementAnnotation(userId: string, measurementId: string, annotation: string) {
    return this.http.post(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/${userId}/measurementAnnotations`,
      {
        userAccountId: userId,
        measurementId: measurementId,
        annotation: annotation
      }
    );
  }

  updateMeasurementAnnotation(
    userId: string,
    measurementId: string,
    annotation: string,
    annotationId: string
  ) {
    return this.http.put(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/${userId}/measurementAnnotations`,
      {
        userAccountId: userId,
        measurementId: measurementId,
        annotation: annotation,
        annotationId: annotationId
      }
    );
  }

  /**
   * Video chat
   */

  sendVideoCallInvitation(userId: string, date: Date): Observable<any> {
    return this.http.post(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/${userId}/${ENDPOINT_VIDEOCONFERENCES}`,
      {
        scheduledOn: date.toISOString()
      }
    );
  }

  getVideoCallInvitations(userId: string) {
    return this.http.get<VideoCallItemDto[]>(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/${userId}/${ENDPOINT_VIDEOCONFERENCES}`
    );
  }

  getVideoCallDetails(userId: string, token: string): Observable<any> {
    return this.http.get(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/${userId}/${ENDPOINT_VIDEOCONFERENCES}/${token}`
    );
  }

  cancelVideoCallInvitation(userId: string, token: string) {
    return this.http.delete(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/${userId}/${ENDPOINT_VIDEOCONFERENCES}/${token}`
    );
  }

  /**
   * END Video chat
   */
}
