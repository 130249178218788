import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { combineLatest, from, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, concatMap, shareReplay, tap } from 'rxjs/operators';
import { AppConfig } from '~shared/app-config';
import { ApplicationInsightsService } from '~shared/services/app-insights.service';
import { AuthProfile } from '../models/auth-profile.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  auth0Client$: Observable<Auth0Client>;
  isAuthenticated$: Observable<boolean>;
  handleRedirectCallback$: Observable<any>;

  loggedIn: boolean = null;

  public readonly userProfile$ = new ReplaySubject<AuthProfile>();

  constructor(private router: Router, private appInsights: ApplicationInsightsService) {
    this.auth0Client$ = (from(
      createAuth0Client({
        domain: AppConfig.settings.auth.domain,
        client_id: AppConfig.settings.auth.clientId,
        redirect_uri: `${window.location.origin}/auth/callback`,
        audience: AppConfig.settings.auth.audience,
        scope: `${AppConfig.settings.auth.scopes.join(' ')}`,
        leeway: 60 * 5 // minutes of clock skew is acceptable to adjust for system clock differences
      })
    ) as Observable<Auth0Client>).pipe(
      shareReplay(1), // Every subscription receives the same shared value
      catchError(err => throwError(err))
    );

    this.isAuthenticated$ = this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.isAuthenticated())),
      tap(result => {
        this.loggedIn = result;
        if (result) {
          this.getUser$().subscribe(user => {
            this.appInsights.setUserId(user.sub);
          });
        } else {
          this.appInsights.clearUserId();
        }
      })
    );

    this.handleRedirectCallback$ = this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
    );
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      tap(client => client.getTokenSilently()),
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(user => {
        this.userProfile$.next(new AuthProfile(user));
      })
    );
  }

  getTokenSilently$(options?): Observable<string> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
    );
  }

  localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    return checkAuth$.pipe(
      tap((response: { [key: string]: any } | boolean) => {
        // If authenticated, response will be user object
        // If not authenticated, response will be 'false'
        this.loggedIn = !!response;
      })
    );
  }

  // A desired redirect path can be passed to login method
  // (e.g., from a route guard)
  login(redirectPath: string = '/') {
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: `${window.location.origin}/auth/callback`,
        appState: { target: redirectPath }
      });
    });
  }

  // Only the callback component should call this method
  // Call when app reloads after user logs in with Auth0
  handleAuthCallback() {
    let targetRoute: string; // Path to redirect to after login processsed
    const authComplete$ = this.handleRedirectCallback$.pipe(
      // Have client, now call method to handle auth callback redirect
      tap(cbRes => {
        // Get and set target redirect route from callback results
        targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
      }),
      concatMap(() => {
        // Redirect callback complete; get user and login status
        return combineLatest(this.getUser$(), this.isAuthenticated$);
      })
    );
    // Subscribe to authentication completion observable
    // Response will be an array of user and login status
    authComplete$.subscribe(([user, loggedIn]) => {
      this.loggedIn = loggedIn;
      // Redirect to target route after callback processing
      this.router.navigate([targetRoute]);
    });
  }

  logout() {
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: AppConfig.settings.auth.clientId,
        returnTo: `${window.location.origin}`
      });
    });
  }
}
