import { Injectable } from '@angular/core';
import { AuthProfile } from '../models/auth-profile.model';
import { UserRole } from '../models/authorization.model';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthorizationService {
  authProfile: AuthProfile;

  constructor(private auth: AuthenticationService) {
    this.auth.userProfile$.subscribe(profile => {
      this.authProfile = profile;
    });
  }

  hasRole(role: UserRole) {
    return this.getUserRoles().includes(role);
  }

  isPatient() {
    return this.getUserRoles().every(x => x === 'Patient');
  }

  getUserRoles(): UserRole[] {
    if (!this.authProfile || !this.authProfile.roles) return [];
    return this.authProfile.roles as UserRole[];
  }

  getNameOfUser(): string {
    if (!this.authProfile) return '';
    return this.authProfile.nickname;
  }

  getRequiresConsent(): boolean {
    return this.authProfile.requiresConsent;
  }
}
