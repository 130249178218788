import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '~auth/data/services/authentication.service';
import { PatientsService } from '~core/users/data/services/patients.service';
import { ConfirmService } from '~shared/services/confirm-modal.service';
import { LanguageService } from '~shared/services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private patientsService: PatientsService,
    private confirmService: ConfirmService,
    private translateService: TranslateService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private languageService: LanguageService,
    public auth: AuthenticationService
  ) {
    this.initI18n();
  }

  private initI18n() {
    this.languageService.initI18n();
  }

  /**
   This is needed for restoring session upon reload.
   */
  ngOnInit() {
    try {
      this.auth.localAuthSetup().subscribe(() => {});
    } catch (error) {
      console.log('CM: AppComponent -> ngOnInit -> error', error);
    }
  }
}
