import { Component, forwardRef, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

/**
 * Custom Auto complete component
 * Takes a list of options and filterProperties
 *
 * @class CmInputAutocompleteComponent
 * @implements {OnInit}
 * @implements {ControlValueAccessor}
 * @implements {Validator}
 */
@Component({
  selector: 'cm-input-autocomplete',
  templateUrl: './cm-input-autocomplete.component.html',
  styleUrls: ['./cm-input-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CmInputAutocompleteComponent),
      multi: true
    }
  ]
})
export class CmInputAutocompleteComponent implements OnInit, ControlValueAccessor {
  @ViewChild('cmAutoComplete') autoCompleteInput: NgbTypeahead;

  @Input() options: any;
  @Input() filterProperties: string[];
  @Input() formatSuggestion: (item: any) => string;
  @Input() suggestionLimit = 10;
  @Input() placeholder: string;
  @Input() disableManualInput = false;
  @Input() isDisabled: boolean;

  inputEnabled: boolean;
  selectedOption: any;
  value: any;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  onChange: any = (value: any) => {};
  onTouched: any = () => {};

  constructor() {}

  ngOnInit(): void {
    this.inputEnabled = !this.disableManualInput;
  }

  /**
   * Suggest a list of options when searching manually or after clicking on the input
   */
  autoComplete = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.autoCompleteInput.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        (term
          ? this.options.filter(option => this.filterOption(option, term))
          : this.options
        ).slice(0, this.suggestionLimit)
      )
    );
    // tslint:disable-next-line: semicolon
  };

  // Function to filter options based on the given properties
  // Checks if the value of the properties contains a part of the searchTerm.
  filterOption(option: any, searchTerm: string) {
    let result: boolean;
    if (!this.filterProperties || this.filterProperties?.length < 1) {
      if (typeof option === 'string') {
        result = option.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
      }
    } else {
      this.filterProperties.forEach(prop => {
        if (typeof option[prop] === 'string') {
          result = option[prop].toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
        }
      });
    }
    return result;
  }

  // Resets the control
  clearInput() {
    this.writeValue(null);
  }

  enableInput() {
    this.inputEnabled = !this.inputEnabled;
  }

  // Event handler for selecting a sugggestion
  onItemSelected({ item }: any) {
    this.writeValue(item);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(obj: any): void {
    this.selectedOption = obj || null;
    this.value = obj || '';
    this.onChange(this.value);
  }

  setDisabledState(isDisabled: boolean): void {
    this.autoCompleteInput?.setDisabledState(isDisabled);
  }
}
