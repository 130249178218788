import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cm-patient-video-chat-host',
  templateUrl: './patient-video-chat-host.component.html',
  styleUrls: ['./patient-video-chat-host.component.scss']
})
export class PatientVideoChatHostComponent implements OnInit {
  readonly sessionId: string;
  readonly token: string;
  readonly locale: string;

  callEnded = false;
  error?: string;

  errorLabel: string;
  sessionEndedLabel: string;

  constructor(private activeRoute: ActivatedRoute, private translate: TranslateService) {
    this.token = this.activeRoute.snapshot.queryParams.t;
    this.sessionId = this.activeRoute.snapshot.queryParams.s;
    this.locale = this.activeRoute.snapshot.queryParams.l;

    this.translate.setDefaultLang('en');
    this.translate.use(this.locale);
    this.translate
      .get(['PATIENT_VIDEOCHAT.ERROR_MESSAGE', 'PATIENT_VIDEOCHAT.SESSION_ENDED'])
      .subscribe(translations => {
        this.errorLabel = translations['PATIENT_VIDEOCHAT.ERROR_MESSAGE'];
        this.sessionEndedLabel = translations['PATIENT_VIDEOCHAT.SESSION_ENDED'];
      });
  }

  ngOnInit(): void {}

  onCallEnded(error?: string) {
    if (error) {
      this.error = error;
    }
    this.callEnded = true;
  }
}
