import { NgModule } from '@angular/core';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { CallbackComponent } from './components/callback/callback.component';
import { AuthorizationService } from './data/services/authorization.service';
import { AuthEnableDirective } from './directives/auth-enable.directive';
import { AuthShowDirective } from './directives/auth-show.directive';
import { AuthGuard } from './guards/auth.guard';

const AUTH_COMPONENTS = [CallbackComponent];
const AUTH_DIRECTIVES = [AuthEnableDirective, AuthShowDirective];

@NgModule({
  imports: [AuthenticationRoutingModule],
  declarations: [...AUTH_COMPONENTS, ...AUTH_DIRECTIVES],
  exports: [...AUTH_COMPONENTS, ...AUTH_DIRECTIVES],
  providers: [AuthorizationService, AuthGuard]
})
export class AuthenticationModule {}
