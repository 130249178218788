<div class="cm-confirm-modal">
  <div class="modal-header">
    <h4 class="modal-title">{{ options.title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="3">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ options.message }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="cancel()" tabindex="2">
      {{ 'COMMON.CANCEL' | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="confirm()" tabindex="1">
      {{ 'COMMON.OK' | translate }}
    </button>
  </div>
</div>
