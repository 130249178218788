export const ENDPOINT_VITALS = 'Vitals';
export const ENDPOINT_MEASUREMENTS = 'Measurements';
export const ENDPOINT_LORA_MEASUREMENTS = 'Lora';
export const ENDPOINT_CSV = 'Csv';
export const ENDPOINT_DYMOLABELS = 'DymoLabels';
export const ENDPOINT_PATIENTS = 'Patients';
export const ENDPOINT_USERAGREEMENT = 'UserAgreements';
export const ENDPOINT_USERS = 'UserAccounts';
export const ENDPOINT_DEVICES = 'Devices';
export const ENDPOINT_TELECOMDEVICES = 'DeviceTelecom';
export const ENDPOINT_ORGANISATIONS = 'Organizations';
export const ENDPOINT_DEVELOPER = 'Developer';
export const ENDPOINT_AUDIT = 'AuditEvents';
export const ENDPOINT_SURVEYS = 'Surveys';
export const ENDPOINT_USERSURVEYS = 'UserSurveys';
export const ENDPOINT_VIDEOCONFERENCES = 'VideoConferences';
export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';
