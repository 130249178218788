import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface CmDropdownItem<TSource> {
  label: string;
  value: any;
  image?: string;
  icon?: string;
  source: TSource;
}

@Component({
  selector: 'cm-dropdown',
  templateUrl: './cm-dropdown.component.html',
  styleUrls: ['./cm-dropdown.component.scss']
})
export class CmDropdownComponent implements OnInit {
  @Input() invalid: boolean;
  @Input() value: CmDropdownItem<any>;
  @Input() defaultLabel: string;
  @Input() items: CmDropdownItem<any>[];
  @Input() disabled = false;
  @Input() showClearButton = false;
  @Output() valueSelected: EventEmitter<any>;

  constructor() {
    this.valueSelected = new EventEmitter<any>();
  }

  ngOnInit() {}

  onSelected(value: CmDropdownItem<any>) {
    this.valueSelected.emit(value);
  }

  get selectedItem() {
    return this.items.find(x => x.value === this.value);
  }

  get selectedItemImage() {
    const item = this.selectedItem;
    return item && item.image ? item.image : null;
  }

  get translationValue() {
    const item = this.selectedItem;
    return item ? item.label : this.value;
  }

  clear() {
    this.value = null;
    this.valueSelected.emit(null);
  }
}
