<div
  class="bg-dark text-white w-100 h-100"
  [ngClass]="{
    'position-relative': isHost,
    'position-absolute': !isHost
  }"
>
  <cm-vonage-video-subscriber
    *ngFor="let stream of streams"
    [stream]="stream"
    [session]="session"
  ></cm-vonage-video-subscriber>

  <div class="position-absolute bg-transparent text-white w-100 h-100" *ngIf="isConnected">
    <p id="title" class="text-center text-white mt-2" *ngIf="isPublished && !hasRemoteConnection">
      {{ 'PROFILE.VIDEOCALL.MSG_WAITING_FOR_HOST' | translate }}
    </p>

    <div id="localStreamWrapper" class="position-absolute">
      <cm-vonage-video-publisher
        [session]="session"
        (streamPublished)="isPublished = true"
      ></cm-vonage-video-publisher>
    </div>

    <button
      type="button"
      class="cm-end-videocall btn btn-danger waves-effect btn-sm"
      *ngIf="isPublished"
      [title]="'PROFILE.VIDEOCALL.BTN_VIDEOCALL_EXIT' | translate"
      (click)="endCall()"
      style="z-index: 10;"
    >
      <i class="mdi mdi-phone-hangup"></i>
    </button>
  </div>
</div>
