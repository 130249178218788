import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { Alert } from '~shared/services/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cm-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent {
  @ViewChild('cmAlert', { static: true }) alertElement: ElementRef;

  @Input()
  alert: Alert;

  @Output()
  alertClick = new EventEmitter();

  constructor(private translateService: TranslateService) {}

  getAlertTypeClass() {
    return { [this.alert.type || 'info']: true };
  }

  getAlertIconClass() {
    switch (this.alert.type) {
      case 'danger':
        return { 'fe-alert-circle': true };
      case 'warning':
        return { 'fe-alert-triangle': true };
      case 'success':
        return { 'fe-check-circle': true };
      case 'info':
        return { 'fe-info': true };
      default:
        break;
    }
  }

  getTitle() {
    const alertType = this.alert.type === 'danger' ? 'ERROR' : this.alert.type.toUpperCase();
    return this.alert.title || this.translateService.instant(`COMMON.${alertType}`);
  }

  onAlertClick() {
    this.alertClick.emit(this.alert);
    this.alertElement.nativeElement.classList.add('hidden');
  }
}
