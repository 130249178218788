import { Component, OnInit } from '@angular/core';
import { NgxDropzonePreviewComponent } from 'ngx-dropzone';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'custom-dropzone-image-preview',
  templateUrl: './custom-dropzone-image-preview.component.html',
  styleUrls: ['./custom-dropzone-image-preview.component.scss'],
  providers: [
    {
      provide: NgxDropzonePreviewComponent,
      useExisting: CustomDropzoneImagePreviewComponent
    }
  ]
})
export class CustomDropzoneImagePreviewComponent extends NgxDropzonePreviewComponent
  implements OnInit {
  imgSrc: string | ArrayBuffer;

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  ngOnInit() {
    if (this.file?.type?.includes('image')) {
      this.readFile().then(result => (this.imgSrc = result));
    } else {
      this.imgSrc = '/assets/images/icon-no-image.svg';
    }
  }
}
