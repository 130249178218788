import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FileDownloadService {
  constructor(private http: HttpClient) {}

  async downloadFromUrl(
    fileName: string,
    downloadUrl: string,
    requestOptions: any = {}
  ): Promise<any> {
    const blob = await this.http
      .get<Blob>(downloadUrl, { ...requestOptions, responseType: 'blob' as 'json' })
      .toPromise();

    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);

    window.open(url, '_blank');

    link.href = url;
    link.download = fileName;
    link.click();

    window.URL.revokeObjectURL(url);
  }

  downloadBase64String(
    fileName: string,
    base64DataString: string,
    mimeType: string = 'application/pdf'
  ) {
    const blob = this.b64toBlob(base64DataString, mimeType);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
    window.URL.revokeObjectURL(blobUrl);
  }

  openDataUrl(url: string, fileName: string = 'test.csv') {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  }

  private b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
