import { Component, Input, OnInit } from '@angular/core';
import { CmDataCardDictionary } from './cm-data-card.model';
import { ValueCache } from 'ag-grid-community';
import * as moment from 'moment';

/**
 * CmDataCardComponent The generic data card directive
 * @param title The title displayed in the card header
 * @param data The data that will be shown in the card. Can be any JSON object; it will be parsed automatically.
 * @author Jason Leeraert
 */
@Component({
  selector: 'cm-data-card',
  templateUrl: './cm-data-card.component.html',
  styleUrls: ['./cm-data-card.component.scss']
})
export class CmDataCardComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() data: object;
  @Input() translationKeyPrefix: string;

  dataDictionary: Array<CmDataCardDictionary> = [];

  constructor() {}

  ngOnInit() {
    if (!this.data) return;
    if (Array.isArray(this.data)) {
      this.data.forEach(item => this.processItem(item));
    } else {
      this.processItem(this.data);
    }
  }

  private processItem(item: any) {
    this.dataDictionary = Object.entries(item).map(([key, value = '-']) => {
      const dictionaryValue =
        value instanceof Date
          ? moment(value)
              .utc()
              .format('l')
          : value;
      const translationKey = this.translationKeyPrefix
        ? `${this.translationKeyPrefix}${key.toUpperCase()}`.replace(/\s+\d+/, '')
        : key;

      return { key: translationKey, value: dictionaryValue };
    });
  }
}
