import { PatientPersonalia } from './patient-personalia.model';
import { Patient } from '../../../users/data/models/patient.model';
import { Organization } from '~shared/data/models/organization.model';

export class PatientProfile {
  organization: Organization;
  patient: Patient;
  personalia: PatientPersonalia;
  thresholdPropertiesGroupedPerDeviceType: Map<string, any[]>;

  constructor(json: any) {
    this.personalia = json.personalia;
    this.patient = json.patient;
    this.organization = json.organization;
    this.thresholdPropertiesGroupedPerDeviceType = json.thresholdPropertiesGroupedPerDeviceType;
  }
}
