import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '~shared/app-config';
import { FeedbackItem } from '~shared/data/models/feedback.model';
import { ENDPOINT_DEVELOPER } from '~shared/data/constants/api.constants';
import { Observable } from 'rxjs';

@Injectable()
export class FeedbackService {
  constructor(private http: HttpClient) {}

  uploadFeedback(userFeedback: FeedbackItem): Observable<any> {
    return this.http.post(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_DEVELOPER}/FeedbackItems`,
      userFeedback
    );
  }
}
