import * as moment from 'moment';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { DATE_FORMAT } from './data/constants/date.constants';

@Injectable({ providedIn: 'root' })
export class MomentDateFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const formattedDate = moment.utc(value.trim());
      if (formattedDate.isValid()) {
        return {
          day: formattedDate.date(),
          month: formattedDate.month() + 1,
          year: formattedDate.year()
        };
      } else {
        const date = moment.utc(value.trim());
        return { day: date.date(), month: date.month() + 1, year: date.year() };
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const mdt = moment.utc([date.year, date.month - 1, date.day]);
    const val = mdt.isValid() ? mdt.format(DATE_FORMAT) : '';
    return val;
  }
}
