import { VitalsDataSet } from './vitals.model';
import * as moment from 'moment';

export interface IPatientSummaryDto {
  vitalsLastConfirmed: string;
  vitalsLastConfirmedBy: string;
  vitals: Array<VitalsDataSet>;
  vitalsPerDeviceTypeAndUnit: Map<any, any>;
  dateOfBirth: string;
  city: string;
}

export class PatientSummary {
  public vitalsLastConfirmed?: Date;
  public vitalsLastConfirmedBy: string;
  public vitals: Array<VitalsDataSet>;
  public vitalsPerDeviceTypeAndUnit: Map<any, any>;
  public city: string;
  public dateOfBirth: Date;

  constructor(json: IPatientSummaryDto) {
    this.vitalsLastConfirmed = json.vitalsLastConfirmed ? new Date(json.vitalsLastConfirmed) : null;
    this.vitalsLastConfirmedBy = json.vitalsLastConfirmedBy;
    this.vitals = (json.vitals || []).map(x => new VitalsDataSet(x));
    this.vitalsPerDeviceTypeAndUnit = json.vitalsPerDeviceTypeAndUnit;
    this.city = json.city;
    this.dateOfBirth = json.dateOfBirth
      ? moment(json.dateOfBirth)
          .utc()
          .toDate()
      : null;
  }

  get lastMeasured() {
    if (this.vitals && this.vitals.length > 0) {
      const dates = this.vitals.map(x => x.lastMeasured.getTime());
      dates.sort((a, b) => a - b);
      return new Date(dates.reverse()[0]);
    }
    return null;
  }
}
