import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICheckbox } from '~shared/data/interfaces/checkbox.interface';

@Component({
  selector: 'cm-checkbox',
  templateUrl: './cm-checkbox.component.html',
  styleUrls: ['./cm-checkbox.component.scss']
})
export class CmCheckboxComponent implements OnInit {
  constructor() {
    this.valueSelected = new EventEmitter<any>();
  }
  @Input() checkbox: ICheckbox<any>;
  @Input() invalid: boolean;
  @Output() valueSelected: EventEmitter<any>;

  ngOnInit() {}

  onSelected(value: any) {
    this.checkbox.selected = !this.checkbox.selected;
    this.valueSelected.emit(this.checkbox);
  }

  get id() {
    return this.checkbox ? this.checkbox.label.replace(' ', '') : '';
  }
}
