export class TableColumn {
  name: string;
  label: string;
  displayValue: ColDisplayValue;
  sortValue?: any;

  constructor(name: string, label: string, displayValue: ColDisplayValue, sortValue?: any) {
    this.name = name;
    this.label = label;
    this.displayValue = displayValue;
    this.sortValue = sortValue;
  }
}

export type ColDisplayValue = (params: any) => HTMLElement | string;
