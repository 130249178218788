<div class="form-group">
  <div class="input-group">
    <input
      type="search"
      class="form-control"
      [placeholder]="'COMMON.SEARCH' | translate"
      #searchInput
    />
  </div>
</div>
