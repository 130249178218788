import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  Input,
  Version
} from '@angular/core';
import { AuthenticationService } from '~auth/data/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthProfile } from '~auth/data/models/auth-profile.model';
import { LoadingService } from '~shared/services/loading.service';
import { ConfirmService } from '~shared/services/confirm-modal.service';
import { LanguageService } from '~shared/services/language.service';
import { ILanguage } from '../data/interfaces/language.interface';
import { UsersService } from '~core/users/data/services/users.service';
import { AlertService } from '~shared/services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeedbackService } from '~shared/services/feedback.service';
import { AuthorizationService } from '~auth/data/services/authorization.service';
import { FeedbackItem } from '~shared/data/models/feedback.model';
import { Router } from '@angular/router';
import { ApplicationVersion } from 'src/app/app.version';
import { UserAgreementService } from '~core/user-agreement/user-agreement.service';
import { FileDownloadService } from '~shared/services/file-download.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  notificationItems: Array<{}>;
  languages: Array<ILanguage>;
  selectedLanguage: ILanguage;
  userProfile: AuthProfile;

  applicationVersion = ApplicationVersion;

  openMobileMenu: boolean;
  userId: string;

  feedbackText = '';
  requiredFeedbackLength = 25;

  @Input() feedback: FeedbackItem;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  @ViewChild('feedbackModal', { static: true })
  feedbackModal: ElementRef;

  constructor(
    public authService: AuthenticationService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private loadingService: LoadingService,
    private confirmService: ConfirmService,
    private usersService: UsersService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private feedbackService: FeedbackService,
    private authorizationService: AuthorizationService,
    private router: Router,
    private userAgreementService: UserAgreementService,
    private fileDownloadService: FileDownloadService
  ) {}

  ngOnInit() {
    this.getAvailableLanguages();
    this.authService.userProfile$.subscribe(profile => {
      this.userProfile = profile;
    });
    this.usersService.getProfileId().then(x => (this.userId = x));
    const currentLang = this.languages.find(x => x.lang === this.translateService.currentLang);
    this.selectedLanguage = currentLang || this.languages[0];
    this.openMobileMenu = false;
  }

  /**
   * Change the language
   * @param language language
   */
  changeLanguage(language) {
    this.selectedLanguage = language;
    this.confirmService
      .confirm({
        message: this.translateService.instant(`ERROR.PAGE_MUST_RELOAD`),
        title: 'Attention!'
      })
      .then(() => {
        this.loadingService.show();
        setTimeout(() => {
          location.reload();
        }, 100);
      })
      .catch(() => {})
      .finally(() => {
        this.languageService.changeLanguage(language.lang);
        this.languageService.setPreferredLanguage(language.lang);
      });
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Change the user's password
   */
  resetPassword() {
    this.confirmService
      .confirm({
        message: this.translateService.instant(`USER.MSG_RESET_PASSWORD`),
        title: this.translateService.instant(`COMMON.WARNING`)
      })
      .then(() => {
        this.usersService.resetPassword().subscribe(() => {
          this.alertService.showAlert({
            type: 'success',
            message: this.translateService.instant(`USER.MSG_PASSWORD_LINK_SENT`, {
              email: this.userProfile.email
            })
          });
        });
      })
      .catch(() => {});
  }

  downloadAcceptedAgreements() {
    return this.userAgreementService
      .getAcceptedUserAgreements(this.userId)
      .subscribe(async result => {
        const b64toUrl = (base64, type = 'application/octet-stream') =>
          `data:${type};base64,${base64}`;

        if (result.loanAgreement !== null) {
          this.fileDownloadService.openDataUrl(
            b64toUrl(result.loanAgreement),
            'Chipmunk-LoanAgreement.pdf'
          );
        }

        if (result.dataConsent !== null) {
          this.fileDownloadService.openDataUrl(
            b64toUrl(result.dataConsent),
            'Chipmunk-DataConsent.pdf'
          );
        }

        if (result.userAgreement !== null) {
          this.fileDownloadService.openDataUrl(
            b64toUrl(result.userAgreement),
            'Chipmunk-UserAgreement.pdf'
          );
        }
      });
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
  }

  showFeedbackModal() {
    this.modalService
      .open(this.feedbackModal, { ariaLabelledBy: 'modal-basic-title', centered: true })
      .result.then(
        () => this.submitFeedback(),
        () => {}
      );
  }

  submitFeedback() {
    const userFeedback = new FeedbackItem({
      feedback: this.feedbackText,
      userAgent: window.navigator.userAgent,
      appRoute: this.router.url
    });
    this.feedbackService.uploadFeedback(userFeedback).subscribe(
      () => {
        this.alertService.showAlert({
          type: 'success',
          message: this.translateService.instant(`USER.MSG_FEEDBACK_RECEIVED`)
        });
        this.feedbackText = '';
      },
      () => {}
    );
  }

  isSubmitDisabled() {
    return this.feedbackText.length < this.requiredFeedbackLength;
  }

  get hasFeedbackProviderRole() {
    return this.authorizationService.hasRole('FeedbackProvider');
  }

  /**
   * Fetches the supported languages
   */
  getAvailableLanguages() {
    this.languages = [
      {
        id: 1,
        name: 'English',
        lang: 'en',
        flag: 'assets/images/flags/flag-en.svg'
      },
      {
        id: 2,
        name: 'Nederlands',
        lang: 'nl',
        flag: 'assets/images/flags/flag-nl.svg'
      },
      {
        id: 3,
        name: 'Deutsch',
        lang: 'de',
        flag: 'assets/images/flags/flag-de.svg'
      }
    ];
  }
}
