export interface IMenuItem {
  title: string;
  icon: string;
  link: string;
  roles: string[];
  submenu: IMenuItem[];

  hasSubmenu(): boolean;
  getSubMenuLink(submenuItem: IMenuItem): string;
}

export class MenuItem implements IMenuItem {
  title: string;
  icon: string;
  link: string;
  roles: string[];
  submenu: IMenuItem[];

  constructor(json: any) {
    this.title = json.title;
    this.icon = json.icon;
    this.link = json.link;
    this.roles = json.roles;
    this.submenu = json.submenu;
  }

  hasSubmenu(): boolean {
    return this.submenu && this.submenu.length > 1;
  }

  getSubMenuLink(submenuItem: IMenuItem): string {
    return `${this.link}${submenuItem.link}`;
  }
}
