<!-- ========== Left Sidebar Start ========== -->
<ng-template #contentTemplate>
  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <ul class="metismenu" id="side-menu" #sideMenu>
      <li class="menu-title">{{ 'MENU.TITLE_MENU' | translate }}</li>

      <li *ngFor="let menuItem of menuItems">
        <a
          href="javascript: void(0);"
          class="waves-effect side-nav-link-ref"
          [ngClass]="!menuItem.hasSubmenu() ? '-hidden' : ''"
          [class.active]="isUrlActive(menuItem.link, false)"
          (click)="navigateTo(menuItem)"
          aria-expanded="false"
        >
          <i [class]="menuItem.icon"></i>
          <span> {{ menuItem.title | translate }} </span>
          <span class="menu-arrow"></span>
        </a>
        <ul class="nav-second-level" aria-expanded="false" *ngIf="menuItem.hasSubmenu()">
          <li *ngFor="let item of menuItem.submenu">
            <a
              aria-expanded="false"
              [routerLink]="menuItem.getSubMenuLink(item)"
              class="side-nav-link-ref"
              [class.active]="isUrlActive(menuItem.getSubMenuLink(item), true)"
              >{{ item.title | translate }}</a
            >
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <!-- End Sidebar -->

  <div class="clearfix"></div>
</ng-template>

<div class="left-side-menu">
  <div class="slimscroll-menu" appSlimScroll *ngIf="!isCondensed">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>

  <div class="slimscroll-menu" *ngIf="isCondensed">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>
</div>
<!-- Left Sidebar End -->
