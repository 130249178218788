import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  TemplateRef
} from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cm-modal',
  templateUrl: './cm-modal.component.html',
  styleUrls: ['./cm-modal.component.scss']
})
export class CmModalComponent implements OnInit {
  @Input() modalRef: NgbModalRef;
  @Input() title: string;
  @Input() submitDisabled;
  @Input() showFooter = true;
  @Output() cancelled: EventEmitter<any> = new EventEmitter();
  @Output() confirmed: EventEmitter<any> = new EventEmitter();

  @HostListener('keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    switch (event.key) {
      case 'Enter':
        this.onSubmitClicked();
        break;
      default:
        break;
    }
  }

  constructor() {}

  ngOnInit() {}

  onDismissClicked() {
    this.modalRef.dismiss('close');
    this.cancelled.emit();
  }

  onCancelClicked() {
    this.modalRef.dismiss('close');
    this.cancelled.emit();
  }

  onSubmitClicked() {
    this.modalRef.close('done');
    this.confirmed.emit();
  }
}
