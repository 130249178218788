export class AddressModel {
  street = '';
  postalCode = '';
  city = '';
  stateOrProvince = '';
  country = '';

  constructor(json: any) {
    this.street = json.street;
    this.postalCode = json.postalCode;
    this.city = json.city;
    this.country = json.country;
    this.stateOrProvince = json.stateOrProvince;
  }
}
