<div class="card cm-card">
  <div class="card-header cm-card-header" *ngIf="title">
    <h3 class="cm-card-title">
      {{ title }}
    </h3>
  </div>
  <div>
    <h4 class="mb-3" *ngIf="subtitle">{{ subtitle }}</h4>
    <table class="table cm-card-table">
      <tbody>
        <tr *ngFor="let item of dataDictionary">
          <th>
            {{ item.key | translate }}
          </th>
          <td>{{ item.value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
