export type UserGender = 'Male' | 'Female' | 'Other' | 'Unknown';
export type PhoneNumberType = 'Home' | 'Mobile' | 'Work' | 'Fax';
export type MeasurementAlertStatus = 'Missed' | 'Lowest' | 'Low' | 'Normal' | 'High' | 'Highest';
export type MeasurementType = 'BloodPressure' | 'Glucose' | 'Weight' | 'Saturation' | 'Steps';
export type NotificationMethod = 'None' | 'Call' | 'Text' | 'Email';

// tslint:disable-next-line: max-line-length
export const emailValidation = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const locationValidation = /^[A-Za-z]+$/;
export const nameValidation = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'|.))*[a-zA-Z\u0080-\u024F]*$/;
export const dutchPostalValidation = /^\d{4}[ ]?[A-Z]{2}$/;
export const canadianPostalValidation = /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/;
export const germanPostalValidation = /^\d{5}$/;
