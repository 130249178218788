import { Manufacturer } from './device-manufacturer.model';
import { ThresholdType } from '~core/user-profile/data/models/thresholdType.model';

export interface IContractProperty {
  name: string;
  unit: string;
  sortOrdinal: number;
  isVitalProperty: boolean;
  hasThresholds: boolean;
  isRequired: boolean;
}

export class DeviceType {
  name: string;
  isDefault: boolean;
  manufacturers: Manufacturer[];
  contractProperties: IContractProperty[];
  available?: boolean;
  deviceTypeTranslationKey: string;
  sortOrdinal: number;

  constructor(json: any) {
    this.name = json.name;
    this.isDefault = json.isDefault;
    this.deviceTypeTranslationKey = `DEVICETYPE.${this.name.toUpperCase()}`;
    this.manufacturers = json.manufacturers;
    this.available = json.available;
    this.contractProperties = (json.contractProperties || []).sort(this.byOrdinal);
    this.sortOrdinal = json.sortOrdinal;
  }

  get vitalProperties(): ThresholdType[] {
    return this.contractProperties.filter(p => p.isVitalProperty).sort(this.byOrdinal);
  }

  get thresholdProperties(): ThresholdType[] {
    return this.contractProperties
      .filter(p => p.isVitalProperty && p.hasThresholds)
      .sort(this.byOrdinal);
  }

  private byOrdinal(a: IContractProperty, b: IContractProperty) {
    return a.sortOrdinal - b.sortOrdinal;
  }
}
