import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from '~shared/app-config';
import { ENDPOINT_PATIENTS, ENDPOINT_VIDEOCONFERENCES } from '~shared/data/constants/api.constants';
import { LoggingService } from '~shared/services/logging.service';
import { VideoCallItemDto } from '~core/users/data/models/video-call-item.dto';

@Injectable({
  providedIn: 'root'
})
export class VideoCallService {
  sessionId: string;
  token: string;
  showVideoCallScreen: boolean;

  private callId: string;
  private userAccountId: string;

  private readonly _showVideoCallSrc: Subject<boolean> = new Subject();
  showVideoCall$: Observable<boolean> = this._showVideoCallSrc.asObservable();

  constructor(private http: HttpClient, private loggingService: LoggingService) {}

  get(token: string) {
    return this.http
      .get(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_VIDEOCONFERENCES}/${token}`)
      .pipe(
        map(res => res),
        tap(res => {
          this.loggingService.debug('VideocallService -> get by Token()', res);
        })
      );
  }

  showVideoCall(call: VideoCallItemDto, userAccountId: string) {
    this.sessionId = call.sessionId;
    this.token = call.clientToken;
    this.callId = call.id;
    this.userAccountId = userAccountId;
    this._showVideoCallSrc.next(true);
  }

  closeVideoCallDialog() {
    this._showVideoCallSrc.next(false);
  }

  cancelVideoCallInvitation() {
    if (!this.userAccountId || !this.callId) return;
    return this.http.delete(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_PATIENTS}/${this.userAccountId}/${ENDPOINT_VIDEOCONFERENCES}/${this.callId}`
    );
  }
}
