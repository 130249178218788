export class Threshold {
  deviceType: string;
  propertyName: string;
  low: number;
  lowest: number;
  high: number;
  highest: number;

  constructor(json: any) {
    this.deviceType = json.deviceType;
    this.propertyName = json.propertyName;
    this.lowest = json.lowest;
    this.low = json.low;
    this.high = json.high;
    this.highest = json.highest;
  }
}

export class ThresholdDtoModel {
  deviceType: string;
  propertyName: string;
  lowest: number;
  low: number;
  high: number;
  highest: number;

  constructor(json: any) {
    this.deviceType = json.deviceType;
    this.propertyName = json.propertyName;
    this.lowest = parseFloat(json.lowest);
    this.low = parseFloat(json.low);
    this.high = parseFloat(json.high);
    this.highest = parseFloat(json.highest);
  }
}
