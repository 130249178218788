import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ImagesService {
  constructor() {}

  getGenderIcon(gender: string) {
    switch (gender) {
      case 'Female':
        return 'mdi mdi-gender-female mr-2';
      case 'Male':
        return 'mdi mdi-gender-male mr-2';
      case 'Other':
        return 'mdi mdi-gender-transgender mr-2';
      case 'Unknown':
        return 'mdi mdi-dots-horizontal mr-2';
    }
  }

  getNotificationMethodIcon(notificationMethod: string) {
    switch (notificationMethod) {
      case 'None':
        return 'mdi mdi-bell-off mr-2';
      case 'Call':
        return 'mdi mdi-phone mr-2';
      case 'Text':
        return 'mdi mdi-cellphone-text mr-2';
      case 'Email':
        return 'mdi mdi-email mr-2';
    }
  }

  getPhoneIcon(type: string) {
    switch (type) {
      case 'Home':
        return 'mdi mdi-phone-classic  mr-2';
      case 'Work':
        return 'mdi mdi-deskphone  mr-2';
      case 'Mobile':
        return ' mdi mdi-cellphone  mr-2';
      case 'Fax':
        return 'mdi mdi-fax  mr-2';
    }
  }

  getDeviceIcon(deviceType: string) {
    switch (deviceType) {
      case 'Hub':
        return 'fi devicesicons-usb-hub';
      case 'WeightScale':
        return 'fi devicesicons-weight';
      case 'BloodPressureMonitor':
        return 'fi devicesicons-blood-pressure';
      case 'ActivityTracker':
        return 'fi devicesicons-fitness';
      case 'BloodGlucoseMeter':
        return 'fi devicesicons-glucose-meter';
      case 'SpO2Meter':
        return 'fi devicesicons-pulse-oximeter';
      case 'Thermometer':
        return 'fi devicesicons-thermometer';
      case 'Interior':
        return 'fi devicesicons-air-humidity';
      case 'HeartRateMonitor':
        return 'fi devicesicons-heart-rate-monitor';
      case 'MobileDongle':
        return 'fi devicesicons-mobile-dongle';
      case 'SIM':
        return 'fi devicesicons-sim-card';
      case 'PeakFlowMeter':
        return 'fi devicesicons-peak-flow-meter';
      case 'SmartMeterReader':
        return 'fi devicesicons-smart-meter-reader';
      case 'DesktopSwitch':
        return 'fi devicesicons-desktop-switch';
    }
  }

  getCountryFlag(country: string) {
    switch (country) {
      case 'Netherlands':
      case 'nl':
        return 'assets/images/flags/flag-nl.svg';
      case 'Canada':
      case 'ca':
      case 'en':
        return 'assets/images/flags/flag-ca.svg';
      case 'Germany':
      case 'de':
        return 'assets/images/flags/flag-de.svg';
      default:
        return '';
    }
  }

  getDeviceImage(manufacturer: string, model: string) {
    const deviceName = manufacturer + '_' + model;
    const imageName = deviceName.replace(/ /g, '_');
    const src = `assets/images/devices/${imageName}.png`;
    return src;
  }

  getDeviceIconAsImage(deviceType: string) {
    return (
      this.getDeviceIcon(deviceType).replace('fi devicesicons-', 'assets/images/devices-icons/') +
      '.svg'
    );
  }

  getFileIcon(contentType: string) {
    switch (contentType) {
      case 'application/pdf':
        return 'mdi mdi-file-pdf-outline';
      case 'text/csv':
        return 'mdi mdi-file-delimited';
      case 'application/json':
        return 'mdi mdi-file-xml';
      case 'application/x-zip-compressed':
        return 'mdi mdi-zip-box';
    }

    if (contentType.includes('image')) return 'mdi mdi-file-image';
    if (contentType.includes('music')) return 'mdi mdi-file-music-outline';
    if (contentType.includes('audio')) return 'mdi mdi-file-music-outline';
    if (contentType.includes('video')) return 'mdi mdi-file-video-outline';
    if (contentType.includes('text')) return 'mdi mdi-file-document-outline';
    return 'mdi mdi-file-outline';
  }
}
