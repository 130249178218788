import { MeasurementModel } from './measurement.model';
import { getHighestAlertClass } from '~shared/util/alert-utils';

export class VitalsDataSet {
  deviceType: string;
  deviceId: string;
  hasUpdatedVitals: boolean;
  lastMeasured: Date;
  measurements: MeasurementModel[];
  hasMissedMeasurement: boolean;
  deviceTypeTranslationKey: string;
  alertCount: number;

  constructor(json: any) {
    this.deviceType = json.deviceType;
    this.deviceId = json.deviceId;
    this.deviceTypeTranslationKey = `DEVICETYPE.${this.deviceType.toUpperCase()}`;
    this.hasUpdatedVitals = json.hasUpdatedVitals;
    this.lastMeasured = new Date(json.lastMeasured);
    this.hasMissedMeasurement = json.hasMissedMeasurement;
    if (json.measurements && Array.isArray(json.measurements)) {
      this.measurements = json.measurements.map(x => new MeasurementModel(x));
    } else if (json.measurements) {
      this.measurements = [new MeasurementModel(json.measurements)];
    }
    this.alertCount = json.alertIndex;
  }

  getVitalValue(name: string) {
    return this.measurements.find(x => x.name === name);
  }

  get highestAlertClass() {
    return getHighestAlertClass(this.measurements, item => item.getAlertClass());
  }
}
