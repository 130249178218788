import { IPatientSummaryDto, PatientSummary } from './patient-summary.model';

export interface IUserSummaryDto {
  id: string;
  firstName: string;
  lastName: string;
  customerNumber: string;
  organizationName: string;
  timeZone: string;
  patient: IPatientSummaryDto;
}

export class UserSummary {
  id: string;
  firstName: string;
  lastName: string;
  customerNumber: string;
  organizationName: string;
  timeZone: string;
  patient: PatientSummary;

  constructor(json: IUserSummaryDto) {
    this.id = json.id;
    this.firstName = json.firstName;
    this.lastName = json.lastName;
    this.customerNumber = json.customerNumber;
    this.organizationName = json.organizationName;
    this.timeZone = json.timeZone;
    this.patient = new PatientSummary(json.patient);
  }

  get name() {
    return `${this.firstName} ${this.lastName}`;
  }
}
