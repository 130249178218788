import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to transform a PascalCase string to Sentence Case.
 * E.g. input: HelloWorld => Hello World
 */
@Pipe({ name: 'cmSentenceCase' })
export class SentenceCasePipe implements PipeTransform {
  transform(input: string): string {
    if (!input) return '';
    return input
      .replace(/([A-Z])/g, match => ` ${match}`)
      .replace(/^./, match => match.toUpperCase());
  }
}
