<div id="wrapper">
  <app-topbar
    (settingsButtonClicked)="onSettingsButtonClicked()"
    (mobileMenuButtonClicked)="onToggleMobileMenu()"
  >
  </app-topbar>

  <app-sidebar [isCondensed]="isCondensed"></app-sidebar>
  <div class="content-page" [ngClass]="{ 'patient-view': isPatientView() }">
    <div class="alert-container --bottom">
      <div *ngFor="let alert of alerts">
        <cm-alert [alert]="alert" (alertClick)="closeAlert(alert)"></cm-alert>
      </div>
    </div>
    <div class="content position-relative" id="cm-content-area">
      <cm-loading-spinner [isLoading]="isLoading"></cm-loading-spinner>
      <ng-template confirm>
        <cm-confirm-modal></cm-confirm-modal>
      </ng-template>

      <!-- content -->
      <router-outlet></router-outlet>

      <div
        class="cm-video-host-wrapper"
        [ngClass]="[
          isMinimized ? 'cm-minimized' : 'cm-maximized',
          isLocatedLeft ? 'cm-video-left' : 'cm-video-right',
          isLocatedTop ? 'cm-video-top' : 'cm-video-bottom'
        ]"
        *ngIf="showVideoCall"
      >
        <cm-vonage-video-client
          [isHost]="true"
          (callEnded)="callEnded($event)"
          [sessionId]="videoCallSessionId"
          [token]="videoCallToken"
        >
        </cm-vonage-video-client>
        <button
          type="button"
          class="cm-window-resize btn btn-outline-light btn-sm"
          (click)="isMinimized = !isMinimized"
        >
          <i [class]="getResizeIcon()"></i>
        </button>
        <button
          type="button"
          class="cm-window-resize btn btn-outline-light btn-sm"
          style="margin-left:45px;"
          (click)="isLocatedLeft = !isLocatedLeft"
        >
          <i [class]="getVideoLocationIconX()"></i>
        </button>
        <button
          type="button"
          class="cm-window-resize btn btn-outline-light btn-sm"
          style="margin-left:90px;"
          (click)="isLocatedTop = !isLocatedTop"
        >
          <i [class]="getVideoLocationIconY()"></i>
        </button>
      </div>
    </div>
  </div>

  <!-- footer -->
  <!-- <app-footer></app-footer> -->
</div>
