import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  constructor() {}

  private readonly isLoading = new ReplaySubject<boolean>();
  public readonly isLoading$ = this.isLoading.asObservable();

  private loadingCount = 0;

  show() {
    this.loadingCount++;
    this.isLoading.next(true);
  }
  hide() {
    this.loadingCount--;
    this.isLoading.next(this.loadingCount > 0);
  }
}
