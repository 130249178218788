import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Organization } from '~shared/data/models/organization.model';
import { AppConfig } from '~shared/app-config';
import { ENDPOINT_ORGANISATIONS } from '~shared/data/constants/api.constants';
import { LoggingService } from '~shared/services/logging.service';

@Injectable({ providedIn: 'root' })
export class OrganizationsService {
  constructor(private http: HttpClient, private loggingService: LoggingService) {}

  getAll(): Observable<Organization[]> {
    return this.http
      .get<Organization[]>(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_ORGANISATIONS}`)
      .pipe(map(res => res.map(x => new Organization(x))))
      .pipe(
        tap(res => {
          this.loggingService.debug('OrganizationsService -> getAll()');
          this.loggingService.logTable(res, 5);
        })
      );
  }

  get(id: any): Observable<Organization> {
    return this.http
      .get<Organization>(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_ORGANISATIONS}/${id}`)
      .pipe(map(res => new Organization(res)))
      .pipe(
        tap(res => {
          this.loggingService.debug('OrganizationsService -> get by ID()', res);
        })
      );
  }

  create(organization: Organization): Observable<any> {
    return this.http
      .post(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_ORGANISATIONS}`, organization)
      .pipe(tap(res => this.loggingService.debug('OrganizationsService -> create()', res)));
  }

  update(id: any, organization: any): Observable<any> {
    return this.http
      .put(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_ORGANISATIONS}/${id}`, organization)
      .pipe(tap(res => this.loggingService.debug('OrganizationsService -> update()', res)));
  }

  delete(id: any): Observable<any> {
    return this.http
      .delete(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_ORGANISATIONS}/${id}`)
      .pipe(tap(res => this.loggingService.debug('OrganizationsService -> delete()', res)));
  }

  getTypes(): Observable<any> {
    return this.http.get(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_ORGANISATIONS}/Types`).pipe(
      tap(res => {
        this.loggingService.debug('OrganizationsService -> getTypes()');
        this.loggingService.logTable(res, 5);
      })
    );
  }
}
