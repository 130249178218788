import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingService } from './services/loading.service';
import { finalize } from 'rxjs/operators';
import { LoggingService } from './services/logging.service';

@Injectable({ providedIn: 'root' })
export class LoadingInterceptor implements HttpInterceptor {
  constructor(public loaderService: LoadingService, private loggingService: LoggingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if (req.url.indexOf(BASE_URL) < 0) return next.handle(req);
    this.loaderService.show();
    const startTime = new Date();
    return next.handle(req).pipe(
      finalize(() => {
        const elapsed = new Date().getTime() - startTime.getTime();
        this.loggingService.info(`Finished ${req.method} on ${req.url} after ${elapsed}ms.`);
        this.loaderService.hide();
      })
    );
  }
}
