<div class="card" *ngIf="isVisible">
  <!-- card header -->
  <div class="card-header bg-{{ color }} text-{{ text }} {{ headerClass }}">
    {{ title }}
    <div class="card-widgets">
      <a
        href="javascript: void(0);"
        (click)="isCollapsed = !isCollapsed"
        [attr.aria-expanded]="!isCollapsed"
        aria-expanded="false"
        aria-controls="cardCollapse"
        class="mdi mdi-minus"
        [ngClass]="{
          'mdi-minus': isCollapsed == false,
          'mdi-plus': isCollapsed == true
        }"
      >
      </a>
      <a href="javascript: void(0);" (click)="remove()"
        ><i
          class="mdi mdi-close close-icon"
          [ngClass]="{
            disabled: canClose === false
          }"
        ></i
      ></a>
    </div>
  </div>
  <!-- End card header -->

  <div id="cardCollapse" class="{{ bodyClass }}" [ngbCollapse]="isCollapsed">
    <ng-content></ng-content>
  </div>

  <div class="card-disabled" *ngIf="isLoading">
    <div class="card-portlets-loader">
      <div class="spinner-border text-primary m-2" role="status"></div>
    </div>
  </div>
</div>
