import { MeasurementAnnotation } from '~core/user-profile/data/models/measurement-annotation.model';

export class Measurement {
  time: string;
  annotations: MeasurementAnnotation[];
  id: string;
  // Optional dynamic properties
  [key: string]: any;

  constructor(json: any) {
    Object.keys(json).forEach(key => {
      this[key] = json[key];
    });
    try {
      this.annotations = JSON.parse(json.annotations);
    } catch (error) {}
  }

  getThresholdStatus(thresholdProp) {
    return this[`${thresholdProp}ThresholdStatus`];
  }

  get annotation(): MeasurementAnnotation {
    return this.annotations ? this.annotations[0] : undefined;
  }

  getAlertValue(thresholdProp: string) {
    switch (this.getThresholdStatus(thresholdProp)) {
      case 'lowExceeded':
      case 'highExceeded':
        return 1;
      case 'highestExceeded':
      case 'lowestExceeded':
        return 2;
      case 'missed':
        return 1;
      case 'normal':
        return 0;
      default:
        return null;
    }
  }

  getAlertClass(thresholdProp: string) {
    switch (this.getThresholdStatus(thresholdProp)) {
      case 'lowExceeded':
      case 'highExceeded':
        return 'warning';
      case 'highestExceeded':
      case 'lowestExceeded':
        return 'danger';
      case 'missed':
        return 'info';
      case 'normal':
      default:
        return '';
    }
  }
}
