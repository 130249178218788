import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './authentication/guards/auth.guard';
import { BREADCRUMB_EMPTY } from './layouts/breadcrumbs/breadcrumbs.constants';
import { LayoutComponent } from './layouts/layout.component';
import { VonageVideoClientComponent } from './videocall/vonage-video-client/vonage-video-client.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    loadChildren: () => import('./core/core.module').then(m => m.CoreModule),
    data: {
      breadcrumb: BREADCRUMB_EMPTY
    },
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: VonageVideoClientComponent,
    loadChildren: () => import('./core/core.module').then(m => m.CoreModule),
    data: {
      breadcrumb: BREADCRUMB_EMPTY
    },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
