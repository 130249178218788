import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { environment } from '~environments';
import { LogLevel } from '~shared/data/constants/log-level.enum';

@Injectable({ providedIn: 'root' })
export class LoggingService {
  private readonly logColors = [
    '#37474F', // LogLevel.All
    '#2E2E2E', // LogLevel.Debug
    '#33b5e5', // LogLevel.Info
    '#ffbb33', // LogLevel.Warning
    '#ff4444', // LogLevel.Error
    '#CC0000', // LogLevel.Fatal
    '#4B515D' // LogLevel.Off
  ];

  constructor() {}

  debug(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  info(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
  }

  warn(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Warning, optionalParams);
  }

  error(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Error, optionalParams);
  }

  fatal(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Fatal, optionalParams);
  }

  log(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.All, optionalParams);
  }

  logTable(param: any[], limit?: number) {
    if (limit > 0) {
      const array = param.slice(0, limit);
      console.table(array);
    } else {
      console.table(param);
    }
  }

  logFormValidationErrors(formGroup: any) {
    const errors = this.collectErrors(formGroup);
    this.error('Form Validation Errors', errors || formGroup);
  }

  private isFormGroup(control: AbstractControl): control is FormGroup {
    return !!(<FormGroup>control).controls;
  }

  private collectErrors(control: AbstractControl): any | null {
    if (this.isFormGroup(control)) {
      return Object.entries(control.controls).reduce((acc, [key, childControl]) => {
        const childErrors = this.collectErrors(childControl);
        if (childErrors) {
          acc = { ...acc, [key]: childErrors };
        }
        return acc;
      }, null);
    } else {
      return control.errors;
    }
  }

  private writeToLog(msg: string, level: LogLevel, params?: any[]) {
    if (this.shouldLog(level)) {
      const levelString = `%c${LogLevel[level]}: `;
      if (params) {
        console.log(levelString + msg, this.getColor(level), params);
      } else {
        console.log(levelString + msg, this.getColor(level));
      }
    }
  }

  private shouldLog(level: LogLevel) {
    if (environment.name === 'prod') {
      return level !== LogLevel.Debug && level !== LogLevel.Off;
    }
    return level !== LogLevel.Off;
  }

  private getColor(level: LogLevel) {
    return `color: ${this.logColors[level]};font-weight:bold`;
  }
}
