import { OrganizationType } from '~shared/data/constants/organizations.constants';
import { AddressModel } from '~shared/data/models/address.model';
import { OrganizationContact } from '~shared/data/models/organization-contact.model';

export class Organization {
  id: string;
  parentId: string;
  name: string;
  address: AddressModel;
  faxNumber: string;
  phoneNumber: string;
  organizationType: OrganizationType = 'Group';
  contacts: OrganizationContact[];
  children: Organization[];
  videoConferencingEnabled: boolean;

  constructor(json: any) {
    this.id = json.id;
    this.parentId = json.parentId;
    this.name = json.name;
    this.address = new AddressModel(json.address);
    this.phoneNumber = json.phoneNumber;
    this.faxNumber = json.faxNumber;
    this.contacts = json.contacts;
    this.children = json.children;
    this.organizationType = json.organizationType;
    this.videoConferencingEnabled = json.videoConferencingEnabled || false;
  }
}
