import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, tap, shareReplay, catchError } from 'rxjs/operators';
import { DeviceType } from '~core/devices/data/models/device-type.model';
import { Device } from '~core/devices/data/models/device.model';
import { DeviceTelecom } from '~core/devices/data/models/device-telecom.model';
import { AppConfig } from '~shared/app-config';
import { ENDPOINT_DEVICES, ENDPOINT_TELECOMDEVICES } from '~shared/data/constants/api.constants';
import { LoggingService } from '~shared/services/logging.service';
import {
  emptyPaginationResult,
  PaginationResult
} from '~shared/data/interfaces/pagination-result.interface';
import { RouterConfig } from '../interfaces/router-config.interface';
import { DeviceDto } from '~shared/data/models/device-dto.interface';
import { DeviceTelecomDto } from '~shared/data/models/device-telecom-dto.interface';
import { DeviceLog } from '../models/device-log.model';

@Injectable({ providedIn: 'root' })
export class DevicesService {
  private deviceTypeCache$: Observable<DeviceType[]>;
  private deviceLogs: Observable<DeviceLog[]>;

  constructor(private http: HttpClient, private loggingService: LoggingService) {}

  getWithFilters(
    searchText?: string,
    currentPage?: number,
    pageSize?: number,
    sortBy?: string,
    sortOrder?: number,
    assigned?: boolean
  ) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append(
      'pageSize',
      `${pageSize ? pageSize : AppConfig.settings.api.pageSize}`
    );
    if (searchText && searchText.length > 2) {
      queryParams = queryParams.append('searchText', `${searchText}`);
    }
    if (currentPage) queryParams = queryParams.append('currentPage', `${currentPage}`);
    if (sortBy) {
      queryParams = queryParams.append('sortBy', sortBy);
      queryParams = queryParams.append(
        'sortDirection',
        sortOrder === 1 ? 'ascending' : 'descending'
      );
    }
    if (typeof assigned === 'boolean') {
      queryParams = queryParams.append('assigned', assigned.toString());
    }

    return this.http
      .get<PaginationResult<Device>>(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_DEVICES}`, {
        params: queryParams
      })
      .pipe(
        map(
          result =>
            ({
              ...result,
              items: result.items.map(item => new Device(item))
            } as PaginationResult<Device>)
        ),
        catchError(error => {
          this.loggingService.error(
            `Could not resolve data for: ${error.url}. Error ${error.status}`
          );
          return of(emptyPaginationResult());
        })
      );
  }

  get(id: string) {
    return this.http
      .get<DeviceDto>(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_DEVICES}/${id}`)
      .pipe(
        map(res => new Device(res)),
        tap(res => {
          this.loggingService.debug('DevicesService -> get by ID()', res);
        })
      );
  }

  create(device: any): Observable<any> {
    return this.http.post(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_DEVICES}`, device).pipe(
      tap(res => {
        this.loggingService.debug('DevicesService -> create()', res);
      })
    );
  }

  update(id: any, device: any): Observable<any> {
    return this.http
      .put(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_DEVICES}/${id}`, device)
      .pipe(tap(res => this.loggingService.debug('DevicesService -> update()', res)));
  }

  delete(id: any): Observable<any> {
    return this.http
      .delete(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_DEVICES}/${id}`)
      .pipe(tap(res => this.loggingService.debug('DevicesService -> delete()', res)));
  }

  getAvailableDeviceTypes(): Observable<DeviceType[]> {
    if (!this.deviceTypeCache$) {
      this.deviceTypeCache$ = this.http
        .get(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_DEVICES}/types`)
        .pipe(
          map((types: any) => {
            return types.map(type => new DeviceType(type));
          }),
          shareReplay(1)
        );
    }
    return this.deviceTypeCache$;
  }

  getDeviceData(
    assigned?: boolean,
    query?: string,
    page?: number,
    pageSize?: number,
    deviceType?: string,
    location?: string
  ): Observable<PaginationResult<Device>> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append(
      'pageSize',
      `${pageSize ? pageSize : AppConfig.settings.api.pageSize}`
    );
    if (query) queryParams = queryParams.append('searchText', `${query}`);
    if (page) queryParams = queryParams.append('currentPage', `${page}`);
    if (assigned !== undefined) queryParams = queryParams.append('assigned', `${assigned}`);
    if (deviceType !== undefined) queryParams = queryParams.append('deviceType', `${deviceType}`);
    if (location) queryParams = queryParams.append('location', `${location}`);
    return this.http
      .get<PaginationResult<Device>>(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_DEVICES}`, {
        params: queryParams
      })
      .pipe(
        map(result => {
          if (
            deviceType == 'SIM' ||
            deviceType == 'PeakFlowMeter' ||
            deviceType == 'SmartMeterReader'
          ) {
            result.items = result.items.map(item => new Device(item));
          } else if (deviceType == 'MobileDongle' || deviceType == 'DesktopSwitch') {
            result.items = result.items
              .filter(t => t.serialNumber !== null)
              .map(item => new Device(item));
          } else {
            result.items = result.items
              .filter(t => t.serialNumber !== null && t.macAddress !== null)
              .map(item => new Device(item));
          }
          return result;
        })
      );
  }

  bulkConfigureRouters(country: string) {
    return this.http
      .put(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_DEVICES}/whitelist`, {
        countries: [country]
      })
      .pipe(tap(res => this.loggingService.debug('DevicesService -> configureRouter()', res)));
  }

  configureRouterWhitelist(userAccountId: string, whitelistDevices: boolean) {
    return this.http
      .put(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_DEVICES}/${userAccountId}/whitelist`, {
        whitelistDevices
      })
      .pipe(tap(res => this.loggingService.debug('DevicesService -> configureRouter()', res)));
  }

  configureTimeZone(userAccountId: string) {
    return this.http
      .put(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_DEVICES}/${userAccountId}/timezone`, null)
      .pipe(tap(res => this.loggingService.debug('DevicesService -> configureTimeZone()', res)));
  }

  getRouterConfig(userAccountId: string) {
    return this.http
      .get<RouterConfig>(
        `${AppConfig.settings.api.baseUrl}/${ENDPOINT_DEVICES}/${userAccountId}/routerconfig`
      )
      .pipe(tap(res => this.loggingService.debug('DevicesService -> getRouterConfig()', res)));
  }

  transferOwnership(fromUser: string, toUser: string) {
    return this.http.post(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_DEVICES}/Ownership`, {
      fromUser,
      toUser
    });
  }

  getDeviceLog(id: string): Observable<DeviceLog[]> {
    this.deviceLogs = this.http
      .get(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_DEVICES}/${id}/GetDeviceLogs`)
      .pipe(
        map((logs: any) => {
          return logs.map(log => new DeviceLog(log));
        })
      );
    return this.deviceLogs;
  }

  createDeviceLog(deviceLog: any): Observable<any> {
    return this.http
      .post(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_DEVICES}/CreateDeviceLog`, deviceLog)
      .pipe(
        catchError(err => {
          return throwError(err);
        }),
        tap(res => this.loggingService.debug('DeviceLogService -> create()', res))
      );
  }

  getTelecomDevice(id: string) {
    return this.http
      .get<DeviceTelecomDto>(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_TELECOMDEVICES}/${id}`)
      .pipe(
        map(res => new DeviceTelecom(res)),
        tap(res => {
          this.loggingService.debug('DevicesTelecomService -> get by ID()', res);
        })
      );
  }

  // Problem is with loggin service lines
  createDeviceTelecom(deviceTelecom: any): Observable<any> {
    return this.http
      .post(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_TELECOMDEVICES}`, deviceTelecom)
      .pipe(
        tap(res => this.loggingService.debug('DeviceTelecomsService -> create()', res)),
        catchError(this.handleError)
      );
  }

  updateDeviceTelecom(iccid: any, deviceTelecom: any): Observable<any> {
    return this.http
      .put(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_TELECOMDEVICES}/${iccid}`, deviceTelecom)
      .pipe(
        tap(res => this.loggingService.debug('DeviceTelecomsService -> update()', res)),
        catchError(this.handleError)
      );
  }

  deleteDeviceTelecom(iccid: any): Observable<any> {
    return this.http
      .delete(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_TELECOMDEVICES}/${iccid}`)
      .pipe(
        tap(res => this.loggingService.debug('DeviceTelecomsService -> delete()', res)),
        catchError(this.handleError)
      );
  }

  private handleError(error: Response): Observable<any> {
    console.error('observable error: ', error);
    return Observable.throw(error.statusText);
  }
}
