import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BREADCRUMB_EMPTY } from '../layouts/breadcrumbs/breadcrumbs.constants';
import { AuthenticationInterceptor } from './authentication.interceptor';
import { CallbackComponent } from './components/callback/callback.component';

const routes: Routes = [
  {
    path: 'auth',
    children: [
      {
        path: 'callback',
        component: CallbackComponent,
        data: {
          breadcrumb: BREADCRUMB_EMPTY
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    }
  ]
})
export class AuthenticationRoutingModule {}
