import { AppConfig } from '~shared/app-config';

export class AuthProfile {
  email: string;
  name: string;
  nickname: string;
  roles: string[];
  picture: string;
  sub: string;
  requiresConsent: boolean;

  constructor(json: any) {
    if (json) {
      this.email = json.email;
      this.name = json.name;
      this.nickname = json.nickname;
      this.picture = json.picture;
      this.sub = json.sub;
      this.roles = json[`${AppConfig.settings.auth.claimsPrefix}/roles`];
      this.requiresConsent =
        json[`${AppConfig.settings.auth.claimsPrefix}/consent`] === true || false;
    }
  }
}
