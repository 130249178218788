import { DailyScheme } from './daily-scheme.model';
import { IMeasurementSchemeDto } from './measurement-scheme-dto.model';

export class MeasurementScheme {
  schedule: DailyScheme[];
  deviceType: string;

  constructor(json: IMeasurementSchemeDto) {
    this.schedule = json.schedule.map(schedule => new DailyScheme(schedule));
    this.deviceType = json.deviceType;
  }
}
