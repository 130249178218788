import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { countries, countryFlags } from '~shared/data/constants/lists.constants';
import { ICountry } from '~shared/data/interfaces/country.interface';
import { AddressModel } from '~shared/data/models/address.model';
import { CmDropdownItem } from '../cm-dropdown/cm-dropdown.component';
import {
  nameValidation,
  dutchPostalValidation,
  canadianPostalValidation,
  germanPostalValidation
} from '~core/users/data/constants/user.constants';

const countryDropdownItems = countries.map(x => ({
  label: x.name,
  value: x.name,
  image: countryFlags[x.name],
  source: x
}));

@Component({
  selector: 'cm-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit {
  countryDropdownItems: CmDropdownItem<ICountry>[];

  @Input()
  submitted: boolean;

  @Input()
  addressFormGroup: FormGroup;

  @Input()
  addressModel: AddressModel;

  @Output()
  countryChanged: EventEmitter<ICountry> = new EventEmitter();

  constructor() {
    this.countryDropdownItems = countryDropdownItems;
  }

  static getPostalCodeValidators(country: ICountry) {
    let validators = [Validators.required, Validators.minLength(2)];
    if (!country) return validators;
    switch (country.name) {
      case 'Netherlands':
        validators = [...validators, Validators.pattern(dutchPostalValidation)];
        break;
      case 'Canada':
        validators = [...validators, Validators.pattern(canadianPostalValidation)];
        break;
      case 'Germany':
        validators = [...validators, Validators.pattern(germanPostalValidation)];
        break;
      default:
        break;
    }
    return validators;
  }

  /**
   * This is a static method that is to be called from the parent component when initialising the form.
   * @param address object to initialise values with. Cannot be null.
   */
  static setupForm(address: AddressModel) {
    const setPostalCodeValidators = (country: ICountry) => {
      const validators = this.getPostalCodeValidators(country);
      if (!country) {
        postalCodeCtrl.reset();
        postalCodeCtrl.disable();
      } else {
        postalCodeCtrl.enable();
        postalCodeCtrl.setValidators(validators);
      }
      form.updateValueAndValidity();
    };

    if (!address) address = new AddressModel({});
    const nameValidators = [Validators.minLength(2), Validators.pattern(nameValidation)];
    const currentCountry = countries.find(x => x.name === address.country);

    const form = new FormGroup({
      street: new FormControl(address.street || '', [Validators.required, Validators.minLength(2)]),
      postalCode: new FormControl(
        address.postalCode || '',
        this.getPostalCodeValidators(currentCountry)
      ),
      city: new FormControl(address.city || '', [Validators.required, ...nameValidators]),
      stateOrProvince: new FormControl(address.stateOrProvince || '', nameValidators),
      country: new FormControl(currentCountry, [Validators.required, Validators.minLength(2)])
    });

    // Update postal code validator when country value is changed
    form.get('country').valueChanges.subscribe(setPostalCodeValidators);

    // Subscribe to value changes to change the text to uppercase
    const postalCodeCtrl = form.get('postalCode');
    postalCodeCtrl.valueChanges.subscribe((value: string) => {
      if (value && value.length > 0) {
        postalCodeCtrl.patchValue(value.toUpperCase(), { emitEvent: false });
      }
    });

    return form;
  }

  get ctrl() {
    return this.addressFormGroup.controls;
  }

  ngOnInit() {}

  getCountryDropdownItem(country: ICountry) {
    return this.countryDropdownItems.find(x => x.source === country);
  }

  getCountryCode() {
    const countryControl = this.addressFormGroup.get('country');
    return countryControl && countryControl.value ? `+(${countryControl.value.code}) ` : '';
  }

  onCountryChanged(country: ICountry) {
    this.addressFormGroup.get('country').setValue(country);
    this.countryChanged.emit(country);
  }
}
