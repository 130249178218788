import { PaginationState, SortDirection } from '../interfaces/pagination-state.interface';
import { PaginationResult } from '../interfaces/pagination-result.interface';

export class Pagination implements PaginationState {
  private _currentPage: number;
  private _pageSize: number;
  private _totalPages: number;
  private _totalRecords: number;
  private _itemCount: number;
  private _searchTerm: string;
  private _sortColumn: string;
  private _sortDirection: SortDirection;
  private _startIndex: number;
  private _endIndex: number;

  private _hasNextPage: boolean;
  private _hasPreviousPage: boolean;

  constructor(result: PaginationResult<any>) {
    Object.assign(this, result);
    delete this['items'];
    this.startIndex = Math.min(result.pageSize * (result.currentPage - 1) + 1, this._itemCount);
    this.endIndex = Math.max(this.startIndex + this._itemCount - 1, 0);
  }

  /**
   * Getter currentPage
   * @return {number}
   */
  public get currentPage(): number {
    return this._currentPage;
  }

  /**
   * Getter pageSize
   * @return {number}
   */
  public get pageSize(): number {
    return this._pageSize;
  }

  /**
   * Getter totalPages
   * @return {number}
   */
  public get totalPages(): number {
    return this._totalPages;
  }

  /**
   * Getter totalRecords
   * @return {number}
   */
  public get totalRecords(): number {
    return this._totalRecords;
  }

  /**
   * Getter itemCount
   * @return {number}
   */
  public get itemCount(): number {
    return this._itemCount;
  }

  /**
   * Setter itemCount
   * @param {number} value
   */
  public set itemCount(value: number) {
    this._itemCount = value;
  }

  /**
   * Getter searchTerm
   * @return {string}
   */
  public get searchTerm(): string {
    return this._searchTerm;
  }

  /**
   * Getter sortColumn
   * @return {string}
   */
  public get sortColumn(): string {
    return this._sortColumn;
  }

  /**
   * Getter sortDirection
   * @return {SortDirection}
   */
  public get sortDirection(): SortDirection {
    return this._sortDirection;
  }

  /**
   * Getter startIndex
   * @return {number}
   */
  public get startIndex(): number {
    return this._startIndex;
  }

  /**
   * Getter endIndex
   * @return {number}
   */
  public get endIndex(): number {
    return this._endIndex;
  }

  /**
   * Getter hasNextPage
   * @return {boolean}
   */
  public get hasNextPage(): boolean {
    return this._hasNextPage;
  }

  /**
   * Getter hasPreviousPage
   * @return {boolean}
   */
  public get hasPreviousPage(): boolean {
    return this._hasPreviousPage;
  }

  /**
   * Setter currentPage
   * @param {number} value
   */
  public set currentPage(value: number) {
    this._currentPage = value;
  }

  /**
   * Setter pageSize
   * @param {number} value
   */
  public set pageSize(value: number) {
    this._pageSize = value;
  }

  /**
   * Setter totalPages
   * @param {number} value
   */
  public set totalPages(value: number) {
    this._totalPages = value;
  }

  /**
   * Setter totalRecords
   * @param {number} value
   */
  public set totalRecords(value: number) {
    this._totalRecords = value;
  }

  /**
   * Setter searchTerm
   * @param {string} value
   */
  public set searchTerm(value: string) {
    this._searchTerm = value;
  }

  /**
   * Setter sortColumn
   * @param {string} value
   */
  public set sortColumn(value: string) {
    this._sortColumn = value;
  }

  /**
   * Setter sortDirection
   * @param {SortDirection} value
   */
  public set sortDirection(value: SortDirection) {
    this._sortDirection = value;
  }

  /**
   * Setter startIndex
   * @param {number} value
   */
  public set startIndex(value: number) {
    this._startIndex = value;
  }

  /**
   * Setter endIndex
   * @param {number} value
   */
  public set endIndex(value: number) {
    this._endIndex = value;
  }

  /**
   * Setter hasNextPage
   * @param {boolean} value
   */
  public set hasNextPage(value: boolean) {
    this._hasNextPage = value;
  }

  /**
   * Setter hasPreviousPage
   * @param {boolean} value
   */
  public set hasPreviousPage(value: boolean) {
    this._hasPreviousPage = value;
  }
}
