import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap, shareReplay, catchError } from 'rxjs/operators';
import { UserRole, UserType } from '~auth/data/models/authorization.model';
import { Organization } from '~shared/data/models/organization.model';
import { UserDto } from '~core/users/data/models/user-dto.model';
import { AppConfig } from '~shared/app-config';
import { ENDPOINT_ORGANISATIONS, ENDPOINT_USERS } from '~shared/data/constants/api.constants';
import { ICheckbox } from '~shared/data/interfaces/checkbox.interface';
import { LoggingService } from '~shared/services/logging.service';
import {
  emptyPaginationResult,
  PaginationResult
} from '~shared/data/interfaces/pagination-result.interface';
import { User } from '../models/user.model';

@Injectable()
export class UsersService {
  private userTypesCache$: Observable<Array<UserType>>;

  constructor(private http: HttpClient, private loggingService: LoggingService) {}

  getAll(pageSize = 25): Observable<PaginationResult<User>> {
    return this.http
      .get<PaginationResult<User>>(
        `${AppConfig.settings.api.baseUrl}/${ENDPOINT_USERS}?includeDeactivated=true&pageSize=${pageSize}`
      )
      .pipe(
        tap(res => {
          this.loggingService.debug('UsersService -> getAll()');
        })
      );
  }

  get(id: any): Observable<UserDto> {
    return this.http
      .get(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_USERS}/${id}?includeDeactivated=true`)
      .pipe(
        map(userDto => new UserDto(userDto)),
        tap(res => {
          this.loggingService.debug(`UsersService -> get by ID(${id})`, res);
        })
      );
  }

  getProfileId(): Promise<string> {
    return this.http
      .get<string>(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_USERS}/PlatformId`)
      .toPromise();
  }

  createLoginAccount(id: string): Observable<UserDto> {
    return this.http
      .post<UserDto>(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_USERS}/${id}/LoginAccount`, null)
      .pipe(tap(res => this.loggingService.debug('UsersService -> createLoginAccount()', res)));
  }

  create(user: UserDto, createLoginAccount: boolean = true): Observable<UserDto> {
    return this.http
      .post<UserDto>(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_USERS}`, {
        ...user,
        bypassLoginAccount: !createLoginAccount
      })
      .pipe(tap(res => this.loggingService.debug('UsersService -> create()', res)));
  }

  update(id: any, user: any): Observable<UserDto> {
    return this.http
      .put<UserDto>(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_USERS}/${id}`, user)
      .pipe(tap(res => this.loggingService.debug('UsersService -> update()', res)));
  }

  activate(id: any): Observable<any> {
    return this.http
      .put(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_USERS}/${id}/Activate`, null)
      .pipe(tap(res => this.loggingService.debug('UsersService -> activate()', res)));
  }

  deactivate(id: any): Observable<any> {
    return this.http
      .put(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_USERS}/${id}/Deactivate`, null)
      .pipe(tap(res => this.loggingService.debug('UsersService -> deactivate()', res)));
  }

  delete(id: any): Observable<any> {
    return this.http
      .delete(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_USERS}/${id}`)
      .pipe(tap(res => this.loggingService.debug('UsersService -> delete()', res)));
  }

  resetPassword() {
    return this.http.post(
      `${AppConfig.settings.api.baseUrl}/${ENDPOINT_USERS}/ResetPassword`,
      null
    );
  }

  getAvailableRoles(): Observable<UserRole[]> {
    return this.http
      .get<UserRole[]>(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_USERS}/roles`)
      .pipe(
        map((roles: any) => {
          return roles.map(role => role.name);
        })
      );
  }

  getAvailableUserTypes(): Observable<UserType[]> {
    if (!this.userTypesCache$) {
      this.userTypesCache$ = this.http
        .get<UserType[]>(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_USERS}/types`)
        .pipe(
          map((types: any) => types.map(type => type.name)),
          shareReplay(1)
        );
    }

    return this.userTypesCache$;
  }

  getAvailableOrganizations(): Observable<Organization[]> {
    return this.http
      .get<Organization[]>(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_ORGANISATIONS}`)
      .pipe(
        map((orgs: Organization[]) =>
          orgs.reduce((accumulator, item) => {
            if (item.organizationType === 'Group') {
              accumulator.concat(item.children);
            }
            accumulator.push(item);
            return accumulator;
          }, [])
        )
      );
  }

  getRoleCheckboxes(roles: UserRole[], userModel: User): ICheckbox<UserRole>[] {
    return roles.map(x => {
      const selected = userModel && userModel.roles && userModel.roles.findIndex(r => r === x) > -1;
      return { label: x, value: x, selected: selected };
    });
  }

  getWithFilters(
    searchText: string,
    currentPage?: number,
    pageSize?: number,
    sortBy?: string,
    sortOrder?: number,
    userAccountType?: UserType,
    includeDeactivated?: boolean
  ) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('includeDeactivated', `${includeDeactivated || 'true'}`);
    queryParams = queryParams.append(
      'pageSize',
      `${pageSize ? pageSize : AppConfig.settings.api.pageSize}`
    );
    if (searchText && searchText.length > 2) {
      queryParams = queryParams.append('searchText', `${searchText}`);
    }
    if (currentPage) queryParams = queryParams.append('currentPage', `${currentPage}`);
    if (sortBy) {
      queryParams = queryParams.append('sortBy', sortBy);
      queryParams = queryParams.append(
        'sortDirection',
        sortOrder === 1 ? 'ascending' : 'descending'
      );
    }
    if (userAccountType) {
      queryParams = queryParams.append('userAccountType', userAccountType);
    }

    return this.http
      .get<PaginationResult<User>>(`${AppConfig.settings.api.baseUrl}/${ENDPOINT_USERS}`, {
        params: queryParams
      })
      .pipe(
        map(
          result =>
            ({
              ...result,
              items: result.items.map(item => new User(item))
            } as PaginationResult<User>)
        ),
        catchError(error => {
          this.loggingService.error(
            `Could not resolve data for: ${error.url}. Error ${error.status}`
          );
          return of(emptyPaginationResult());
        })
      );
  }
}
