import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PatientVideoChatHostComponent } from './patient-video-chat-host/patient-video-chat-host.component';

const routes: Routes = [
  {
    path: 'videochat',
    children: [
      {
        path: '',
        component: PatientVideoChatHostComponent
      },
      {
        path: ':token',
        component: PatientVideoChatHostComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class VideoCallRoutingModule {}
