import { Injectable, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

/**
 * Options passed when opening a confirmation modal
 */
export interface ConfirmOptions {
  /**
   * The title of the confirmation modal
   */
  title: string;

  /**
   * The message in the confirmation modal
   */
  message: string;
}

/**
 * An internal service allowing to access, from the confirm modal component, the options and the modal reference.
 * It also allows registering the TemplateRef containing the confirm modal component.
 *
 * It must be declared in the providers of the NgModule, but is not supposed to be used in application code
 */
@Injectable({ providedIn: 'root' })
export class ConfirmState {
  /**
   * The last options passed ConfirmService.confirm()
   */
  options: ConfirmOptions;

  /**
   * The last opened confirmation modal
   */
  modal: NgbModalRef;

  /**
   * The template containing the confirmation modal component
   */
  template: TemplateRef<any>;
}

/**
 * A confirmation service, allowing to open a confirmation modal from anywhere and get back a promise.
 */
@Injectable({ providedIn: 'root' })
export class ConfirmService {
  constructor(private modalService: NgbModal, private state: ConfirmState) {}

  /**
   * Opens a confirmation modal
   * @param options the options for the modal (title and message)
   * @returns {Promise<any>} a promise that is fulfilled when the user chooses to confirm, and rejected when
   * the user chooses not to confirm, or closes the modal
   */
  async confirm(options: ConfirmOptions): Promise<boolean> {
    this.state.options = options;
    this.state.modal = this.modalService.open(this.state.template, { centered: true });
    try {
      const result = await this.state.modal.result;
      return !!result;
    } catch (error) {
      throw error;
    }
  }
}
