export class BackendError {
  error: string;

  constructor(e: { error: string }) {
    this.error = e.error;
  }

  toString() {
    return this.error;
  }
}
