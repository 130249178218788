export const BREADCRUMB_EMPTY = '';
export const BREADCRUMB_HOME = 'Home';
export const BREADCRUMB_DASHBOARD = 'Dashboard';
export const BREADCRUMB_USERS = 'Users';
export const BREADCRUMB_DEVICES = 'Devices';
export const BREADCRUMB_ORGANISATIONS = 'Organizations';
export const BREADCRUMB_AUTH = 'Auth';
export const BREADCRUMB_CREATE = 'Create';
export const BREADCRUMB_EDIT = 'Edit';
export const BREADCRUMB_PROFILE = 'Profile';
