export class FeedbackItem {
  feedback: string;
  userAgent: string;
  appRoute: string;

  constructor(json: any) {
    this.feedback = json.feedback;
    this.userAgent = json.userAgent;
    this.appRoute = json.appRoute;
  }
}
