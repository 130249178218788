<div class="cm-paginated-table">
  <div class="table-wrapper">
    <table class="table datatables">
      <thead>
        <tr>
          <th *ngFor="let column of tableColumns">
            {{ column.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          tr
          *ngFor="let row of tableRows; let i = index"
          (click)="onRowClicked(i)"
          (dblclick)="onRowDoubleClicked(i)"
          [class.active]="i == selectedRow"
        >
          <td *ngFor="let column of tableColumns">
            <span [innerHTML]="column.displayValue(row[column.name])"></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="mt-2">
        <div class="d-flex justify-content-between">
          <div
            class="mt-1"
            [translate]="'COMMON.PAGINATION_INFO'"
            [translateParams]="{
              startIndex: pagination.startIndex,
              endIndex: pagination.endIndex,
              total: pagination.totalRecords
            }"
          ></div>
          <ngb-pagination
            [collectionSize]="pagination.totalRecords"
            [(page)]="pagination.currentPage"
            [pageSize]="pagination.pageSize"
            (pageChange)="onPageChanged($event)"
            [maxSize]="10"
            [rotate]="true"
            [ellipses]="false"
          >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
