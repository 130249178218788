import { Directive, HostListener, Renderer2, ElementRef, Input } from '@angular/core';

@Directive({ selector: '[rippleEffect]' })
export class RippleEffectDirective {
  constructor(private renderer: Renderer2, private elem: ElementRef) {}

  @Input() rippleColor:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'info'
    | 'warning'
    | 'danger'
    | 'success'
    | 'disabled';

  @HostListener('click', ['$event'])
  onClick(e) {
    if (this.rippleColor === 'disabled') return;
    const currentElem = this.elem.nativeElement;
    currentElem.classList.add('ripple');
    if (this.rippleColor) currentElem.classList.add(`-${this.rippleColor}`);

    e = e.touches ? e.touches[0] : e;
    const r = currentElem.getBoundingClientRect(),
      d = Math.sqrt(Math.pow(r.width, 2) + Math.pow(r.height, 2)) * 2;
    currentElem.style.cssText = `--s: 0; --o: 1;`;
    currentElem.offsetTop;
    currentElem.style.cssText = `--t: 1; --o: 0; --d: ${d}; --x:${e.clientX -
      r.left}; --y:${e.clientY - r.top};`;
  }
}
