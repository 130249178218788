import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEnCa from '@angular/common/locales/en-CA';
import localeEnDe from '@angular/common/locales/en-DE';
import localeEnNl from '@angular/common/locales/en-NL';
import localeFrCa from '@angular/common/locales/fr-CA';
import localeNl from '@angular/common/locales/nl';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxMaskModule } from 'ngx-mask';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutsModule } from './layouts/layouts.module';
import { AppConfig } from './shared/app-config';
import { CustomErrorHandler } from './error-handler';
import { SharedModule } from './shared/shared.module';
import { AuthenticationModule } from '~auth/authentication.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { IconsModule } from './icons/icons.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Factory method to load app config from json file.
export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

registerLocaleData(localeNl, 'nl');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeFrCa, 'fr-CA');
registerLocaleData(localeEnNl, 'en-NL');
registerLocaleData(localeEnCa, 'en-CA');
registerLocaleData(localeEnDe, 'en-DE');

@NgModule({
  declarations: [AppComponent],
  providers: [
    AppConfig,
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfig], multi: true },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler
    }
  ],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    AuthenticationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgBootstrapFormValidationModule.forRoot(),
    NgxMaskModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    IconsModule,
    SharedModule.forRoot(),
    DragDropModule,
    LayoutsModule,
    AppRoutingModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(config: NgbTooltipConfig) {
    config.placement = 'bottom';
  }
}
