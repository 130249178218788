<div class="input-group">
  <input
    #cmAutoComplete="ngbTypeahead"
    [placeholder]="placeholder"
    [ngbTypeahead]="autoComplete"
    [inputFormatter]="formatSuggestion"
    [resultFormatter]="formatSuggestion"
    [editable]="inputEnabled"
    [focusFirst]="true"
    class="form-control"
    type="text"
    (focus)="focus$.next($event.target.value)"
    (click)="click$.next($event.target.value)"
    (selectItem)="onItemSelected($event)"
    [readonly]="!inputEnabled"
    [(ngModel)]="value"
  />
  <div
    class="input-group-append"
    [hidden]="!disableManualInput || isDisabled"
    (click)="enableInput()"
  >
    <div class="btn" [ngClass]="inputEnabled ? 'btn-info' : 'btn-outline-info'">
      <i class="mdi mdi-pencil"></i>
    </div>
  </div>
  <div class="input-group-append" [hidden]="!selectedOption" (click)="clearInput()">
    <div class="btn btn-outline-dark">
      <i class="mdi mdi-close"></i>
    </div>
  </div>
</div>
