import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AppConfig } from '../shared/app-config';
import { LoggingService } from '../shared/services/logging.service';
import { AuthenticationService } from './data/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth = this.injector.get(AuthenticationService);
    const logger = this.injector.get(LoggingService);
    if (req.url.indexOf(AppConfig.settings.api.baseUrl) < 0) return next.handle(req);
    return auth.getTokenSilently$().pipe(
      mergeMap(token => {
        logger.warn('Current Token:', token);
        const tokenReq = req.clone({
          setHeaders: { Authorization: `Bearer ${token}` }
        });
        return next.handle(tokenReq);
      }),
      catchError(err => throwError(err))
    );
  }
}
