import { DeviceDto } from '~shared/data/models/device-dto.interface';

export class Device {
  id: string;
  type: string;
  manufacturer: string;
  model: string;
  chipmunkBarcodeNumber: string;
  manufacturerSku: string;
  serialNumber: string;
  macAddress: string;
  routerMacAddress: string;
  location: string;
  dateReceived: string;
  userAccountId: string;
  userAccountFullName: string;
  manualMeasurements: boolean;
  iccid: string;

  constructor(json: DeviceDto) {
    this.id = json.id;
    this.type = json.type;
    this.manufacturer = json.manufacturer;
    this.model = json.model;
    this.chipmunkBarcodeNumber = json.chipmunkBarcodeNumber;
    this.manufacturerSku = json.manufacturerSku;
    this.serialNumber = json.serialNumber;
    this.macAddress = json.macAddress;
    this.location = json.location;
    this.dateReceived = json.dateReceived;
    this.routerMacAddress = json.routerMacAddress;
    this.userAccountFullName = json.userAccountFullName;
    this.userAccountId = json.userAccountId;
    this.manualMeasurements = json.manualMeasurements;
    this.iccid = json.iccid;
  }
}
