import { UserRole, UserType } from '~auth/data/models/authorization.model';
import { Patient } from './patient.model';

export class User {
  id: string;
  type: UserType;
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  roles: UserRole[];
  patient: Patient;
  organizationId: string;
  correspondenceLanguageCode: string;
  customerNumber: string;
  hasLoginAccount: boolean;
  hasAcceptedAllAgreements?: boolean;
  agreementResponseMode: string;

  constructor(json: any) {
    this.email = json.email;
    this.firstName = json.firstName;
    this.lastName = json.lastName;
    this.roles = json.roles;
    this.isActive = !json.isDeactivated;
    this.id = json.id;
    this.type = json.type;
    this.patient = json.patient ? new Patient(json.patient) : null;
    this.organizationId = json.organizationId;
    this.correspondenceLanguageCode = json.correspondenceLanguageCode;
    this.customerNumber = json.customerNumber;
    this.hasLoginAccount = json.hasLoginAccount;
    this.hasAcceptedAllAgreements = json.hasAcceptedAllAgreements;
    this.agreementResponseMode = json.agreementResponseMode;
  }

  get name(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
